import React, { useState } from 'react';
import { Box, Typography, Switch, Collapse, Badge } from '@mui/material';
import CustomAlert from '../../../../cidgets/CustomAlert';
import FilterDistressDetails from './FilterDistressDetails';
import FilterChips from './FilterChips';
import SmartUtils from '../../../../../helpers/SmartUtils';
const FilterDistressSetting = ({ drProjectData, distressData, appliedFilters, setAppliedFilters, selectedDefects }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedRanges, setSelectedRanges] = useState([]);
    const [value, setValue] = useState([]);
    const [selectedCrack, setSelectedCrack] = useState([]);
    const [selectedTransversePosition, setSelectedTransversePosition] = useState([]);
    const [selectedSeverity, setSelectedSeverity] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertStatus, setAlertStatus] = useState('success');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const { defectRequirements, dataInfoV2: dataInfo, defectNames } = distressData;
    const defectList = ['0', '1', '2', '3', '5', '7', '12', '13'];
    const hidePropertiesList = drProjectData.projectType === 4 ? ["type", "area", "volume", "profile", "depth"] : ["type", "area", "volume", "profile"];
    const handleCrackChange = (event) => {
        setSelectedCrack(event.target.value);
    }
    const handleSelectedTransversePositionChange = (event) => {
        setSelectedTransversePosition(event.target.value);
    }
    const handleSelectedSeverityChange = (event) => {
        setSelectedSeverity(event.target.value);
    }
    const handleFilterChange = (defectType, filter) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [defectType]: filter.target.value,
        }));
    };
    const handleRangeChange = (defectType, range) => {
        setSelectedRanges((prev) => ({
            ...prev,
            [defectType]: range.target.value,
        }));
    };
    const handleClearAll = () => {
        setSelectedFilters([]);
        setSelectedRanges([]);
        setValue([]);
        setSelectedCrack([]);
        setSelectedTransversePosition([]);
        setSelectedSeverity([]);
    };
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    const createFilterObject = (defectKey, filter) => {
        let filterObject;
        defectKey = Number(defectKey);
        if (filter === 'transversePosition') {
            filterObject = {
                id: SmartUtils.generateRandomId(),
                defectName: defectKey,
                selectedProperty: selectedFilters[defectKey],
                operator: '=',
                value: `${selectedTransversePosition.key}`,
            };

            if (defectKey === 1) {
                filterObject.crackType = selectedCrack;
            }
        } else if (filter === 'severity') {
            filterObject = {
                id: SmartUtils.generateRandomId(),
                defectName: defectKey,
                selectedProperty: selectedFilters[defectKey],
                operator: '=',
                value: `${selectedSeverity.key}`,
            };

            if (defectKey === 1) {
                filterObject.crackType = selectedCrack;
            }
        }
        else if (defectKey === 1) {
            filterObject = {
                id: SmartUtils.generateRandomId(),
                defectName: defectKey,
                selectedProperty: selectedFilters[defectKey],
                crackType: selectedCrack,
                operator: selectedRanges[defectKey],
                value: Number(value[defectKey]),
            };
        } else {
            filterObject = {
                id: SmartUtils.generateRandomId(),
                defectName: defectKey,
                selectedProperty: selectedFilters[defectKey],
                operator: selectedRanges[defectKey],
                value: Number(value[defectKey]),
            };
        }
        const hasUndefinedValues = Object.values(filterObject).some((val) => val === undefined || Number.isNaN(val) || val === 'undefined');
        if (hasUndefinedValues) {
            setAlertMessage("Please fill in all required fields!");
            setOpenSnackbar(true);
            setAlertStatus("error");
            return null; // Return null to indicate an invalid object
        }

        return filterObject;
    };
    const handleApply = (defectKey, filter) => {
        const newFilter = createFilterObject(defectKey, filter);
        if (!newFilter) {
            // Don't proceed if filterObject is invalid
            return;
        }
        setAlertStatus("success");

        // console.log(Object.values(newFilter).length, "newFilter");
        setAppliedFilters((prev) => {
            const { isDuplicate, updatedFilters } = SmartUtils.applyUniqueFilter(prev, defectKey, newFilter);
            if (isDuplicate) {
                setAlertMessage("This filter is already applied!");
                setOpenSnackbar(true);
                setAlertStatus("error");
                return prev;
            }
            setAlertMessage("Filters applied successfully!");
            setOpenSnackbar(true);
            return updatedFilters;
        });

        handleClearAll();
    };

    const handleRemoveFilter = (defectKey, id) => {
        setAppliedFilters((prev) => {
            const updatedFilters = {
                ...prev,
                [defectKey]: prev[defectKey].filter((filter) => filter.id !== id),
            };

            // If no filters remain for a defect key, delete it
            if (updatedFilters[defectKey].length === 0) {
                delete updatedFilters[defectKey];
            }

            return updatedFilters;
        });
    };
    const handleValueChange = (defectType, newValue) => {
        setValue((prev) => ({
            ...prev,
            [defectType]: newValue,
        }));
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    const hasAppliedFilters = Object.values(appliedFilters).some(
        (filterArray) => filterArray.length > 0
    );
    return (
        <>
            <CustomAlert open={openSnackbar} onClose={handleSnackbarClose} text={alertMessage} severity={alertStatus} />
            <Box display="flex">
                <Typography variant="subtitle1" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    Filter by Distress
                </Typography>
                <Badge
                    badgeContent={SmartUtils.getTotalFiltersCount(appliedFilters)}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    max={9}
                >
                    <Switch
                        checked={isOpen}
                        onChange={toggleOpen}
                        name="toggleSwitch"
                        color="primary"
                    />
                </Badge>
            </Box>
            {/* Applied filter chips */}
            <Collapse in={isOpen}>
                <Box mt={2} display="flex" flexWrap="wrap" maxWidth="100%">
                    {hasAppliedFilters && <Typography sx={{ marginRight: '10px' }} display="flex" alignItems="center" variant="subtitle1">
                        Applied Filters:
                    </Typography>}
                    {hasAppliedFilters && Object.entries(appliedFilters).map(([type, filterArray]) => (
                        <Box key={type} sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                            {filterArray.map((filter) => {
                                const selectedProperty = SmartUtils.getStringToUpperCase(filter.selectedProperty);
                                const defectName = defectNames[filter.defectName] ?? '';
                                const transversePositionType = filter.selectedProperty === 'transversePosition' ? Number(filter.value) : null;
                                const severityType = filter.selectedProperty === 'severity' ? Number(filter.value) : null;
                                const crackType = Number(filter.crackType) ?? null;
                                // Fetching names for transversePosition and severity types
                                const transversePositionName = transversePositionType !== null && SmartUtils.getListOfObjectValuesData(dataInfo.transversePosition)[transversePositionType];
                                const severityName = severityType !== null && SmartUtils.getListOfObjectValuesData(dataInfo.severity)[severityType];
                                const crackName = crackType !== null && SmartUtils.getListOfObjectValuesData(dataInfo.type)[crackType];
                                const label = `${defectName} ${crackName ? `(${SmartUtils.getStringToUpperCase(crackName.name)})` : ''} : ${selectedProperty} ${filter.operator}
                                ${transversePositionName ?
                                        `${SmartUtils.getStringToUpperCase(transversePositionName.name)}`
                                        : severityName
                                            ? `${SmartUtils.getStringToUpperCase(severityName.name)}`
                                            : `${filter.value} ${SmartUtils.getPropertyUnit(filter.selectedProperty)}`
                                    }`;
                                return (
                                    <FilterChips
                                        key={filter.id}
                                        label={label}
                                        onDelete={() => handleRemoveFilter(type, filter.id)} // Remove filter
                                        color="primary"
                                        variant="outlined"
                                    />
                                )
                            })}
                        </Box>
                    ))}
                </Box>
                <div>
                    {defectRequirements.map((defect) => {
                        if (selectedDefects.length > 0) {
                            if (selectedDefects.includes(Number(defect.key)) && defectList.includes(defect.key)) {
                                const defectType = defect.name;
                                const defectKey = defect.key;
                                const availableFilters = defect.allProperties
                                    .filter(property => !hidePropertiesList.includes(property))
                                    .map(property => ({
                                        original: property,
                                        display: SmartUtils.getStringToUpperCase(property),
                                    })) ?? [];
                                const selectedFilter = selectedFilters[defectKey] || '';
                                const selectedRange = selectedRanges[defectKey] || '';
                                return (
                                    <FilterDistressDetails
                                        key={defectKey}
                                        defectKey={defectKey}
                                        isOpen={isOpen}
                                        selectedFilter={selectedFilter}
                                        handleFilterChange={(filter) => handleFilterChange(defectKey, filter)}
                                        availableFilters={availableFilters}
                                        selectedRange={selectedRange}
                                        handleRangeChange={(range) => handleRangeChange(defectKey, range)}
                                        defectType={defectType}
                                        value={value}
                                        handleValueChange={handleValueChange}
                                        onSelectSeverity={handleSelectedSeverityChange}
                                        selectedSeverity={selectedSeverity}
                                        onSelectTransversePosition={handleSelectedTransversePositionChange}
                                        selectedTransversePosition={selectedTransversePosition}
                                        handleCrackChange={handleCrackChange}
                                        selectedCrack={selectedCrack}
                                        dataInfo={dataInfo}
                                        handleApply={handleApply}
                                        handleClearAll={handleClearAll}
                                    />
                                );
                            }
                        }
                        else if (defectList.includes(defect.key)) {
                            const defectType = defect.name;
                            const defectKey = defect.key;
                            const availableFilters = defect.allProperties
                                .filter(property => !hidePropertiesList.includes(property))
                                .map(property => ({
                                    original: property,
                                    display: SmartUtils.getStringToUpperCase(property),
                                })) ?? [];
                            const selectedFilter = selectedFilters[defectKey] || '';
                            const selectedRange = selectedRanges[defectKey] || '';
                            return (
                                <FilterDistressDetails
                                    key={defectKey}
                                    defectKey={defectKey}
                                    isOpen={isOpen}
                                    selectedFilter={selectedFilter}
                                    handleFilterChange={(filter) => handleFilterChange(defectKey, filter)}
                                    availableFilters={availableFilters}
                                    selectedRange={selectedRange}
                                    handleRangeChange={(range) => handleRangeChange(defectKey, range)}
                                    defectType={defectType}
                                    value={value}
                                    handleValueChange={handleValueChange}
                                    onSelectSeverity={handleSelectedSeverityChange}
                                    selectedSeverity={selectedSeverity}
                                    onSelectTransversePosition={handleSelectedTransversePositionChange}
                                    selectedTransversePosition={selectedTransversePosition}
                                    handleCrackChange={handleCrackChange}
                                    selectedCrack={selectedCrack}
                                    dataInfo={dataInfo}
                                    handleApply={handleApply}
                                    handleClearAll={handleClearAll}
                                />
                            );
                        }
                    })}
                </div>
            </Collapse>
        </>
    );
};

export default FilterDistressSetting;
