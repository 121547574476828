import React, { useEffect, useState } from 'react'
import { Box, Card, ListItem, ListItemText, Typography, Stack, Grid, IconButton, Button, Tooltip, CircularProgress, Badge } from '@mui/material'
import ProjectNotification from '../components/ProjectNotification';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from "react-router-dom";
import { generateReportDistress, updateProject } from '../../../../../store/actions/projectActions';
import { connect } from "react-redux";
import { GlobalHotKeys } from "react-hotkeys";
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';
import SmartUtils from '../../../../../helpers/SmartUtils';
import DistressOverviewChart from './DistressOverviewChart';
import DistressFilter from './DistressFilter';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DateParser from '../../../../../helpers/DateParser';
import ProjectDetails from './ProjectDetails';
import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import { useAccessControlContext } from '../../../../../contexts/AccessControlProvider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const SurfaceEvaluationTopbar = (props) => {
    const { permissions } = useAccessControlContext();
    const { canGenerateReport, canSeeProjectNotification } = permissions;
    const { handleFastDistress, datastore, distressProjectPredictions, startEndLocation, filteredGpsData, isFilterOpen, selectedDefects, handleGpsDataFilterChange, getSelectedDefectNames, defectNames, conditionMethod, setConditionMethod, sectionLength, setSectionLength, filtersData, setFiltersData, appliedFilters, setAppliedFilters } = props;
    const { distressData } = datastore;
    const { drProjectData, proid, chargesV3, userData } = props;
    const { projectTitle, projectType, dateCreated } = drProjectData;
    const topbarSection1Width = 30;
    const topbarSection2Width = 70;
    const date = dateCreated
        ? DateParser(dateCreated, 0)
        : DateParser(dateCreated, 2);

    // DIALOG CONTROLS
    ///////////////////////////////////////////////////////////
    const [openOverview, setOpenOverview] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);

    const [isDialogOpen, setDialogOpen] = useState(false);

    const [customCategories, setCustomCategories] = useState([]);
    const [error, setError] = useState(false);
    const [drivingSideError, setDrivingSideError] = useState(false);
    const [surveyDateError, setSurveyDateError] = useState(false);
    const [processingTimeError, setProcessingTimeError] = useState(false);
    const [typeOfRoadError, setTypeOfRoadError] = useState(false);
    const [processingTime, setProcessingTime] = useState(drProjectData.processingTime ?? '');

    const [values, setValues] = useState({
        projectName: drProjectData.projectTitle,
        drivingSide: drProjectData.roadSide,
        typeOfRoad: drProjectData.typeOfRoad ?? "",
        cameraPosition: "",
        cameraDirection: "",
        cameraMountingHeight: "",
        cameraInclination: "",
        surveyDate: drProjectData.surveyDate ?? "",
        // processingTime: drProjectData.processingTime ?? "",
        identificationNo: drProjectData.identificationNo ?? "",
        clientName: drProjectData.clientName ?? "",
        streetName: drProjectData.streetName ?? "",
        startingAddress: drProjectData.startingAddress ?? "",
        endingAddress: drProjectData.endingAddress ?? "",
        direction: drProjectData.direction ?? "",
        totalLanes: drProjectData.totalLanes ?? "",
        laneNumber: drProjectData.laneNumber ?? ""
    });

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleFilterOpen = () => {
        setOpenFilter(true);
    };

    const handleDetailsOpen = () => {
        setOpenDetails(true);
    };

    const handleFilterClose = () => {
        setOpenFilter(false);
    };

    const handleDetailsClose = () => {
        setOpenDetails(false);
    };

    const handleOverviewOpen = () => {
        setOpenOverview(true);
    };

    const handleOverviewClose = () => {
        setOpenOverview(false);
    };
    ////////////////////////////////////////////////////////////

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
            newHandlers[action] = (event) => {
                if (event) {
                    event.preventDefault();
                }
                handler();
            };
        }
        return newHandlers;
    };

    const handleGenReport = () => {
        // //////console.log("generate report")
        // window.open(`https://deepstrada.page.link/distress-report`);
        props.generateReportDistress(drProjectData.proid, selectedDefects, conditionMethod)
    }

    const keyMap = {
        OVERVIEW_MAC: "command+p",
        OVERVIEW_WIN: "ctrl+p",
        REPORT_MAC: "command+g",
        REPORT_WIN: "ctrl+g",
    };

    const handlers = preventDefaultHandlers({
        OVERVIEW_MAC: handleOverviewOpen,
        OVERVIEW_WIN: handleOverviewOpen,
        REPORT_MAC: handleGenReport,
        REPORT_WIN: handleGenReport,
    });

    const su = new SmartUtils()
    const [os, setOs] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const data = await su._checkOS(navigator);
            setOs(data);
        }

        fetchData()
    }, [])

    const { ctrl } = su._platformKeys(os?.platform)
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/home`;
        navigate(path);
    };

    const titleStyle = { fontWeight: 'bold', fontSize: '14px' }

    useEffect(() => {
        if (props.project.isModalClose === true) {
            handleDialogClose();
        }
    }, [props.project]);
    const handleSubmit = (e) => {
        if (projectType === 1 || projectType === 3 || projectType === 4 || projectType === 5) {

            if (values.projectName.length === 0) {
                setError(true);
            }
            else if (values.drivingSide === "") {
                setDrivingSideError(true);
            }
            else if (processingTime === "") {
                setProcessingTimeError(true);
            }

            else if (values.surveyDate === "") {
                setSurveyDateError(true);
            }
            // else if (values.cameraPosition === "") {
            //   setCameraPositionError(true);
            // }
            // else if (values.cameraDirection === "") {
            //   setCameraDirectionError(true);
            // }

            else {
                // //////console.log("project data: ", values, projectType)
                e.preventDefault();
                props.updateProject(
                    proid,
                    {
                        identificationNo: values.identificationNo,
                        clientName: values.clientName,
                        streetName: values.streetName,
                        startingAddress: values.startingAddress,
                        endingAddress: values.endingAddress,
                        direction: values.direction,
                        totalLanes: values.totalLanes,
                        laneNumber: values.laneNumber,
                        projectTitle: values.projectName,
                        roadSide: values.drivingSide,
                        processingTime: processingTime,
                        surveyDate: values.surveyDate,
                        typeOfRoad: values.typeOfRoad,
                        projectType: projectType,
                        categories: customCategories,
                    });
                handleDetailsClose();
            }
        }

        else {
            if (values.projectName.length === 0) {
                setError(true);
            }
            else {
                e.preventDefault();
                props.updateProject(
                    proid,
                    {
                        identificationNo: values.identificationNo,
                        clientName: values.clientName,
                        streetName: values.streetName,
                        startingAddress: values.startingAddress,
                        endingAddress: values.endingAddress,
                        direction: values.direction,
                        totalLanes: values.totalLanes,
                        laneNumber: values.laneNumber,
                        projectTitle: values.projectName,
                        roadSide: values.drivingSide,
                        processingTime: processingTime,
                        surveyDate: values.surveyDate,
                        typeOfRoad: values.typeOfRoad,
                        projectType: projectType,
                        categories: customCategories,
                    });
                handleDetailsClose();

            }


        }
    };


    const badgeCount = SmartUtils.getTotalFiltersCount(appliedFilters);
    return (
        <>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <SimpleDialogBox
                fullScreen
                open={openOverview}
                onClose={handleOverviewClose}
                title="Results overview"
                cancelText='Close'
                bodyWidget={
                    <DistressOverviewChart drProjectData={drProjectData} startEndLocation={startEndLocation} datastore={datastore} distressProjectPredictions={distressProjectPredictions} estimatedDistanceKm={drProjectData?.estimatedDistanceKm ?? 0} conditionMethod={conditionMethod} />
                }
            />
            <SimpleDialogBox
                open={openFilter}
                onClose={handleFilterClose}
                cancelText='Close'
                title="Analysis settings"
                fullScreen={userData.dev}
                width={SmartUtils.supportCCIProject(drProjectData.projectType) ? 900 : 600}
                bodyWidget={
                    <DistressFilter userData={userData} proid={proid} filteredGpsData={filteredGpsData} drProjectData={drProjectData} isFilterOpen={isFilterOpen} selectedDefects={selectedDefects} handleGpsDataFilterChange={handleGpsDataFilterChange} getSelectedDefectNames={getSelectedDefectNames} defectNames={defectNames} conditionMethod={conditionMethod} setConditionMethod={setConditionMethod} sectionLength={sectionLength} setSectionLength={setSectionLength} distressData={distressData} fitersData={filtersData} setFiltersData={setFiltersData}
                        appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters}
                    />
                }
            />
            <SimpleDialogBox
                open={openDetails}
                onClose={handleDetailsClose}
                title="Project details "
                width={600}
                bodyWidget={
                    <ProjectDetails drProjectData={drProjectData} proid={proid} projectType={projectType} error={error}
                        setError={setError}
                        drivingSideError={drivingSideError}
                        setDrivingSideError={setDrivingSideError}
                        surveyDateError={surveyDateError}
                        setSurveyDateError={setSurveyDateError}
                        processingTimeError={processingTimeError}
                        setProcessingTimeError={setProcessingTimeError}
                        typeOfRoadError={typeOfRoadError}
                        setTypeOfRoadError={setTypeOfRoadError}
                        values={values}
                        setValues={setValues}
                        customCategories={customCategories}
                        setCustomCategories={setCustomCategories}
                        chargesV3={chargesV3}
                        processingTime={processingTime}
                        setProcessingTime={setProcessingTime}
                    />
                }
                showPositive
                positiveButtonWidget={
                    props.project.isProjectUpdating === true ? (
                        <>
                            <Button
                                variant="contained"
                                disabled
                                sx={{
                                    "&.Mui-disabled": {
                                        backgroundColor: "#025196",
                                        color: "#ffffff",
                                    },
                                    marginInline: "2px",
                                    textTransform: "initial",
                                    color: "#025196",
                                    backgroundColor: "#025196",
                                    ":hover": {
                                        backgroundColor: "#025196",
                                    },
                                }}
                            >
                                <CircularProgress
                                    variant="indeterminate"
                                    size={15}
                                    sx={{ color: "white" }}
                                />
                                <span style={{ paddingLeft: "15px" }}>
                                    Updating details...
                                </span>
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            disabled={projectType === undefined ? true : false}
                            sx={{
                                marginInline: "2px",
                                textTransform: "initial",
                                backgroundColor: "#025196",
                                ":hover": {
                                    backgroundColor: "#025196",
                                },
                            }}
                            onClick={handleSubmit}
                        >
                            Update details
                        </Button>
                    )
                }
            />
            <SimpleDialogBox
                open={isDialogOpen}
                onClose={handleDialogClose}
                title={'Confirm generate report'}
                bodyWidget={
                    <>

                        <ListItem
                            disablePadding
                            sx={{ paddingBlock: '5px' }}
                        >
                            <ListItemText
                                primary={<Typography variant="body1" sx={titleStyle}>Selected defects: </Typography>}
                                secondary={selectedDefects.length > 0 ? (
                                    selectedDefects.map((defect, index) => {
                                        switch (defect) {
                                            case 0:
                                                return 'Pothole';
                                            case 1:
                                                return 'Crack';
                                            case 2:
                                                return 'Subsidence';
                                            case 3:
                                                return 'Ravelling';
                                            case 4:
                                                return 'Rutting';
                                            case 5:
                                                return 'Patch';
                                            case 10:
                                                return 'Polished aggregate';
                                            case 12:
                                                return 'Vegetation';
                                            case 13:
                                                return 'Edge deterioration';
                                            default:
                                                return 'Unknown Defect';
                                        }
                                    }).join(', ')
                                ) : (
                                    'None'
                                )}
                            />
                        </ListItem>

                        <ListItem
                            disablePadding
                            sx={{ paddingBlock: '5px' }}
                        >
                            <ListItemText
                                primary={<Typography variant="p" sx={titleStyle}>Section length: </Typography>}
                                secondary={sectionLength}
                            />
                        </ListItem>

                        <ListItem
                            disablePadding
                            sx={{ paddingBlock: '5px' }}
                        >
                            <ListItemText
                                primary={<Typography variant="p" sx={titleStyle}>Condition method: </Typography>}
                                secondary={conditionMethod === 0 ? 'RCI' : conditionMethod === 1 ? 'PCI' : 'None'}
                            />
                        </ListItem>


                    </>
                }
                showPositive
                positiveButtonWidget={
                    props.project.showGenReportDistressLoader === true ?
                        <>
                            <Button variant="contained" disabled sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: '#025196',
                                    color: '#ffffff'
                                },
                                marginInline: '2px',
                                textTransform: 'initial',
                                color: '#025196',
                                backgroundColor: '#025196', ':hover': {
                                    backgroundColor: '#025196'
                                }
                            }} >
                                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Generating...</span>
                            </Button>
                        </>
                        :
                        <>
                            <Button variant="contained"
                                sx={{
                                    marginInline: '2px',
                                    textTransform: 'initial',
                                    backgroundColor: '#025196', ':hover': {
                                        backgroundColor: '#025196'
                                    },
                                }} onClick={handleGenReport}>Accept</Button>
                        </>
                }
            />

            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
                    <div style={{
                        borderRadius: '50%'
                    }}>
                        <Card sx={{
                            borderRadius: '50px', backgroundColor: '#fff', '&:hover': {
                                backgroundColor: '#fafafa',
                            },
                        }} elevation={4}>
                            <Tooltip title="Back to dashboard" arrow placement='bottom'>
                                <IconButton aria-label="roundedBtn" style={{ backgroundColor: 'transparent', }} onClick={routeChange}>
                                    <KeyboardArrowLeft fontSize="large" sx={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>
                        </Card>
                    </div>
                </Grid>
                <Grid item xs={10} sm={10} md={11} lg={11} xl={11} sx={{ backgroundColor: 'transparent', borderRadius: '15px' }}>
                    <Card elevation={4} sx={{ backgroundColor: '#FAFAFA', borderRadius: '15px' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' }, width: { xs: '0%', md: `${topbarSection1Width}%` } }} style={{ height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={`${projectTitle} - ${date}`} placement="bottom">
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        className='font-color'
                                        sx={{ paddingLeft: '20px', cursor: 'pointer', display: { xs: "none", sm: "none", md: 'flex', lg: 'flex', xl: 'flex' } }}
                                    >
                                        {projectTitle ? (
                                            projectTitle.length > 15 ? (
                                                <span>{projectTitle.slice(0, 15)}... — {date}</span>
                                            ) : (
                                                <span>{projectTitle} — {date} </span>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </Typography>
                                </Tooltip>
                            </Box>



                            <Box sx={{ width: { xs: `${100}%`, md: `${topbarSection2Width}%` }, justifyContent: { xs: 'space-evenly', md: 'end' } }} style={{ marginRight: { xs: '20px', sm: '20px', md: '0px' }, height: '100%', backgroundColor: 'transparent', float: 'left', display: 'flex', alignItems: 'center', }}>

                                <Stack direction="row" spacing={0.5}>
                                    {/* Setting */}
                                    {badgeCount > 0 ?
                                        <Tooltip title={`${badgeCount} filters applied`} arrow placement='bottom'>
                                            <Badge
                                                badgeContent={badgeCount}
                                                color="error"
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'left',
                                                }}
                                                max={9}
                                            >
                                                <IconButton onClick={handleFilterOpen}>
                                                    <SettingsOutlinedIcon sx={{ color: 'black' }} />
                                                </IconButton>
                                            </Badge>
                                        </Tooltip> :
                                        <Tooltip title={'Analysis settings'} arrow placement='bottom'>
                                            <IconButton onClick={handleFilterOpen}>
                                                <SettingsOutlinedIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {/* Fast editor */}
                                    {drProjectData.stitchVideoPath && <Tooltip title={`Fast distress edit/add/delete`} arrow placement='bottom'>
                                        <IconButton size="small"
                                            sx={{
                                                backgroundColor: 'tranparent', color: 'black', padding: '5px', marginInline: '0px', ':hover': {
                                                    backgroundColor: 'transparent'
                                                }
                                            }}
                                            onClick={handleFastDistress}
                                        >
                                            <EditRoadOutlinedIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                    </Tooltip>}
                                    {/* Report */}
                                    <Tooltip title={`Use ${ctrl.name}${ctrl.plus}G to generate report`} arrow placement='bottom'>
                                        <IconButton onClick={handleClick}>
                                            <DocumentScannerOutlinedIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                    </Tooltip>
                                    {/* Result Overview */}
                                    <Tooltip title={`Use ${ctrl.name}${ctrl.plus}P to overview the result`} arrow placement='bottom'>
                                        <IconButton onClick={handleOverviewOpen}>
                                            <DonutSmallOutlinedIcon
                                                sx={{ color: 'black' }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    {/* Info */}
                                    <Tooltip title={'Project details'} arrow placement='bottom'>
                                        <IconButton onClick={handleDetailsOpen}>
                                            <InfoOutlinedIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                    </Tooltip>
                                    {/* Notification */}
                                    {canSeeProjectNotification ? <ProjectNotification /> : <></>}
                                </Stack>
                            </Box>
                        </div>
                    </Card>
                </Grid>
            </Grid >
        </>
    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        project: state.project,
        projNotifs: state.firestore.ordered.ProjNotifs,
        userData: state.firebase.profile,
        companyData: state.firestore.data.Company,
        datastore: state.datastore,
        chargesV3: state.firestore.data.ChargesV3,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (proid, projectData) => dispatch(updateProject(proid, projectData)),
        generateReportDistress: (proid, selectedDefects, conditionMethod) => dispatch(generateReportDistress(proid, selectedDefects, conditionMethod)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurfaceEvaluationTopbar);
