import { Button, Card, CircularProgress, Grid, IconButton, Tab, Tabs } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SurfaceEvaluationTopbar from './surfaceEvaluationComponents/SurfaceEvaluationTopbar';
import CustomProgress from '../../../cidgets/CustomProgress';
import SmartUtils from '../../../../helpers/SmartUtils';
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useGenerateSignedUrl } from '../../../../chooks/useGenerateSignedUrl';
import SurfaceEvaluationVideoPlayer from './subpages/afterInference/SurfaceEvaluationVideoPlayer';
import { useNavigate, useParams } from 'react-router-dom';
import DistressMap from './surfaceEvaluationComponents/DistressMap';
import { connect } from 'react-redux';
import { advancedAnalysisSetting, analysisSetting, depthCrossSectionalProfile, editorAddDistress, editorUpdateDistress, getDownSamplingData, moveDistressMultipleToArchive, moveDistressToArchive, saveDepthCrossSectionalPoints } from '../../../../store/actions/projectActions';
import SegmentShifterV2 from './surfaceEvaluationComponents/SegmentShifterV2';
import CustomizedDividers from './subpages/potholeInspection/CustomizedDividers';
import EditOptions from './subpages/potholeInspection/EditOptions';
import DistressEditorPlayer from './surfaceEvaluationComponents/DistressEditorPlayer';
import CustomAlert from '../../../cidgets/CustomAlert';
import SimpleDialogBox from '../../../cidgets/SimpleDialogBox';
const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 'auto',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.2)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
  },
  activeTab: {
    color: "white",
    backgroundColor: "#025196",
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderTop: '3px solid #025196',
    borderRight: '3px solid #025196',
    borderLeft: '3px solid #025196',
    borderBottom: 'none',
    '&:hover': {
      backgroundColor: "#025196",
    }
  },

}));
const AfterSurfaceEvaluationInferenceGroupDashboard = (props) => {
  // startendlocation 
  const { secId } = useParams();
  const { proid, drProjectData, distressProjectPredictions, link, datastore, project } = props;
  const { distressArchiveMessage, distressArchiveIsDoneAndSent, showDistressArchiveLoader,
    updateDistressArchiveMessage, updateDistressArchiveIsDoneAndSent,
    addDistressArchiveMessage, addDistressArchiveIsDoneAndSent
  } = project;
  const { distressData } = datastore;
  const { dimensions } = drProjectData;
  const { height, width } = dimensions;
  // const [videoPlayerState, setVideoPlayerState] = useState(null)
  const [filteredGpsData, setFilteredGpsData] = useState(Object.values(distressProjectPredictions));
  const [selectedDefects, setSelectedDefects] = useState(drProjectData.selectedDefects ? drProjectData.selectedDefects : []);
  const [appliedFilters, setAppliedFilters] = useState(drProjectData.advancedFilters ? drProjectData.advancedFilters : {});
  const [conditionMethod, setConditionMethod] = useState(
    drProjectData.conditionMethod
      ? drProjectData.conditionMethod
      : drProjectData.projectType === 4
        ? (drProjectData.conditionIndex ? drProjectData.conditionIndex[0] : -1)
        : (drProjectData.conditionIndex ? drProjectData.conditionIndex[0] : 0)
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sectionLength, setSectionLength] = useState(10);
  const [segmentIndex, setSegmentIndex] = useState(0);
  const [newCurrentPage, setNewCurrentPage] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isWholeDistressVisualization, setIsWholeDistressVisualization] = useState(false);
  const [distressPinned, setDistressPinned] = useState(null);
  const [crossSectionalPoints, setCrossSectionalPoints] = useState([width * 0.5, height * 0.5, width * 0.8, height * 0.8]);
  const [fitersData, setFiltersData] = useState([]);
  const [defaultCrossSectionalPoints, setDefaultCrossSectionalPoints] = useState([width * 0.5, height * 0.5, width * 0.8, height * 0.8]);
  const [sections, setSections] = React.useState(0);
  const [pinned, setPinned] = useState(null);
  const [isDepthCrossSectional, setIsDepthCrossSectional] = useState(false);
  const [vidDivDimen, setVidDivDimen] = useState({ height: 1, width: 1 });
  // const isVideoActive = videoPlayerState?.readyState === 4;
  const headerRef = useRef();
  const tabRef = useRef();
  const videoRef = useRef();
  const playerRef = useRef(null);

  const classes = useStyles();
  let navigate = useNavigate();
  const RATE = 0.1;
  let gpsDataCombined = [];
  let detectionsList = [];
  let predictions = Object.values(distressProjectPredictions);
  const realFps = 'fps' in drProjectData ? drProjectData.fps : 59.94005994005994
  const FPS = Number(realFps).toFixed(0);
  const headerHeight = headerRef?.current?.clientHeight ?? 0;
  const tabHeight = tabRef?.current?.clientHeight ?? 0;
  const su = new SmartUtils();
  const overallCustomBorderPadding = 16;
  const cardWhiteStyle = { "borderRadius": "15px", };
  const videoSectionHeight = 50;
  const mapSectionHeight = 50;
  const defectNames = SmartUtils.getDefectNamesList(drProjectData.projectType);

  const videosPerPage = 4;
  const videosList = Object.values(drProjectData?.groupVideosInfo);
  videosList.sort((a, b) => a.groupId - b.groupId);
  const [selectVideo, setSelectVideo] = useState(videosList[0])
  const totalPages = Math.ceil(videosList?.length / videosPerPage);
  const currentVideos = videosList.slice(
    newCurrentPage * videosPerPage,
    (newCurrentPage + 1) * videosPerPage
  );
  const isGroupData = drProjectData.groupVideosInfo && drProjectData.groupVideosInfo.length > 1 ? true : false;

  //////////////////// Fast edit ////////////////////////////////////////////////

  const comparePlayerRef = useRef(null);
  const vidDivRef = useRef(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [rectangles, setRectangles] = useState([]);
  const [editorMode, setEditorMode] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteBboxList, setDeleteBboxList] = useState([]);
  const [deleteMultipleBbox, setDeleteMultipleBbox] = useState(false);
  const [showPointer, setShowPointer] = useState(false);
  const [isAddingDistress, setIsAddingDistress] = useState(false);
  const [inputFrame, setInputFrame] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [filteredFrameData, setFilteredFrameData] = useState([]);
  const [selectedRecId, setSelectedRecId] = useState(null);
  const [openDistressDialog, setOpenDistressDialog] = useState(false);
  const [svgDimensions, setSvgDimensions] = useState({ height: 1, width: 1, scale: 1, offsetTop: 0, offsetLeft: 0 });
  const [fieldValues, setFieldValues] = useState({
    sectionId: '',
    gpsLat: '',
    gpsLng: '',
    defectId: '',
    basicImagingClassState: '',
    frameNumberState: '',
    classification: '',
  });
  const [imageDimensions, setImageDimensions] = useState({ height: 1, width: 1 });
  const [stitchCurrentTime, setStitchCurrentTime] = useState(0);

  const totalFrames = drProjectData.stitchFrame ? drProjectData.stitchFrame : drProjectData.frames;
  const stitchVideoHeight = drProjectData.stitchHeight ?? 0;
  const stitchlVideoWidth = drProjectData.stitchWidth ?? 0;
  const stitchFps = drProjectData.stitchFps ? drProjectData.stitchFps : FPS;
  const wholeProjectDetections = Object.values(distressProjectPredictions).sort((a, b) => a.endIndex - b.endIndex);
  const [openArchiveAlert, setOpenArchiveAlert] = useState(false);
  const [openEditAlert, setOpenEditAlert] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClearSelection = () => {
    setRectangles((prevRectangles) => {
      const newRectangles = [...deleteBboxList, ...prevRectangles];
      return newRectangles;
    })
    setDeleteBboxList([]);
    setDeleteMultipleBbox(false);
    setDeleteDialog(false);
  }
  const handleFastDistress = () => {
    setEditorMode(true);
    setIsEditMode(false);
    setSelectedId(null);
    setRectangles([]);
  };
  const handleOpenDeleteDialog = () => {
    setDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  };
  const closeBoundingBox = () => {
    setShowPointer(false);
    setIsAddingDistress(false);
  };
  const handleSelectDelete = () => {
    setDeleteMultipleBbox(true);
    closeBoundingBox();
  };
  const handleDeselectDelete = () => {
    setDeleteMultipleBbox(false);
  };
  const addNewBoundingBox = () => {
    setShowPointer(true);
    setIsAddingDistress(true);
  };
  const handleEditorNextFrame = () => {
    const secondPerFrame = 1 / stitchFps;
    setInputFrame((prevFrame) => prevFrame + secondPerFrame)
    comparePlayerRef.current.forward(secondPerFrame);
  };
  const handleEditorPrevFrame = () => {
    const secondPerFrame = 1 / stitchFps;
    setInputFrame((prevFrame) => prevFrame - secondPerFrame)
    comparePlayerRef.current.replay(secondPerFrame);
  };
  const handleInputFrame = (e) => {
    const newFrame = parseInt(e.target.value);
    if (newFrame < 0) {
      setInputFrame(0);
      comparePlayerRef.current.seek(0);
    }
    else if (newFrame > totalFrames) {
      // alert(`Frame can be between 0 and ${totalFrames}`)
      setInputFrame(totalFrames / stitchFps)
      comparePlayerRef?.current?.seek(totalFrames / stitchFps);
    }
    else {
      setInputFrame(newFrame / stitchFps);
      comparePlayerRef?.current?.seek(newFrame / stitchFps);
    }
  };
  const handleDelete = () => {
    const updatedRectangles = rectangles.filter(rect => rect.id !== selectedRecId);
    const data = filteredFrameData.filter((data) => data.defectId === selectedRecId);
    if (data.length > 0) {
      props.moveDistressToArchive(proid, data[0].sectionId, data[0].defectId);
    }
    setRectangles(updatedRectangles);
    setSelectedRecId(null);
    setSelectedId(null);
    setOpenDistressDialog(false);
    setIsEditMode(false);
    if (isAddingDistress) {
      setShowPointer(true);
    }
  };
  const handleChange = (prop) => (event) => {
    setFieldValues({ ...fieldValues, [prop]: event.target.value });
  };
  const applyFiltersToSections = (sections, filters) => {
    return sections.map((section) => {
      const filteredDetections = Object.values(section.detections).filter((defect) => {
        const defectFilters = filters[defect?.versioning?.defectName ?? defect.defectName]; // Get filters for the defect type
        if (!defectFilters) return true; // Include if no filters defined for this type
        return defectFilters.every(({ selectedProperty, crackType, operator, value }) => {

          // Check crackType if provided
          if (crackType !== undefined) {
            const defectType = defect.versioning ? Number(defect.versioning.type) : Number(defect.type);
            if (defectType !== Number(crackType)) return false; // Exclude if crackType doesn't match
          }
          const adjustedValue = selectedProperty === "depth" ? value / 1000 : value;
          // Apply the filter condition
          switch (operator) {
            case ">":
              return defect[selectedProperty] > adjustedValue;
            case "<":
              return defect[selectedProperty] < adjustedValue;
            case "=":
              return defect[selectedProperty] == adjustedValue; // Loose equality for value comparison
            case ">=":
              return defect[selectedProperty] >= adjustedValue;
            case "<=":
              return defect[selectedProperty] <= adjustedValue;
            default:
              return true; // Skip unrecognized operators
          }
        });
      });

      // Include section with filtered detections or reset to empty object if none match
      return {
        ...section,
        detections: filteredDetections.length > 0
          ? filteredDetections.reduce((acc, defect, index) => {
            acc[index] = defect;
            return acc;
          }, {})
          : {},
      };
    });
  };
  const onStitchCurrentTimeChange = (currentTime) => {
    setStitchCurrentTime(Number(currentTime))
  }
  const handleDeleteBbox = (data) => {
    setRectangles((prevRectangles) => prevRectangles.filter((rect) => rect.id !== data.id));
    const deleteDetectionData = filteredFrameData.filter((eachDetection) => eachDetection.defectId === data.id);
    if (deleteDetectionData.length > 0) {
      setDeleteBboxList((prevDeleteBboxList) => {
        const newList = [
          {
            ...data,
            detectionId: drProjectData.projectType === 5 ? deleteDetectionData[0]['id'] : deleteDetectionData[0]['defectId'],
            sectionId: deleteDetectionData[0]['sectionId'],
          }
          , ...prevDeleteBboxList];
        return newList;
      })
    }
    else {
      alert(`Couldn't find the data for deletion`)
    }

  }
  const handleSelectedDefectData = (data) => {
    if (selectedDefects.length > 0) {
      const finalFilteredData = data.map((section) => {
        const filteredDetections = Object.values(section.detections).filter((value) => selectedDefects.includes(value?.versioning?.defectName ?? value.defectName));
        return {
          ...section,
          detections: filteredDetections,
        }
      });
      if (Object.values(appliedFilters).length > 0) {
        const getFilteredAppliedData = applyFiltersToSections(finalFilteredData, appliedFilters);
        return getFilteredAppliedData;
      }
      return finalFilteredData;
    }
    else if (Object.values(appliedFilters).length > 0) {
      const getFilteredAppliedData = applyFiltersToSections(data, appliedFilters);
      return getFilteredAppliedData;
    }
    return data;
  }

  useEffect(() => {
    const time = Math.floor(stitchFps * stitchCurrentTime);
    const filteredList = wholeProjectDetections[time];
    const appliedFilteredList = filteredList && handleSelectedDefectData([filteredList]);
    if (appliedFilteredList && Object.values(appliedFilteredList[0].detections).length > 0) {
      const sectionId = appliedFilteredList[0].sectionId;
      const detectionsList = Object.values(appliedFilteredList[0].detections).map(detection => ({
        ...detection,
        ...detection?.versioning,
        sectionId
      }));
      setFilteredFrameData(detectionsList);
    } else {
      setFilteredFrameData([]);
    }
  }, [stitchCurrentTime, appliedFilters, distressProjectPredictions, selectedDefects]);
  // useEffect(() => {
  //   const time = Math.floor(stitchFps * stitchCurrentTime);
  //   const filteredList = wholeProjectDetections[time];
  //   const appliedFilteredList = filteredList && applyFiltersToSections([filteredList], appliedFilters)[0];
  //   if (appliedFilteredList && appliedFilteredList.detections) {
  //     const sectionId = appliedFilteredList.sectionId;
  //     const detectionsList = Object.values(appliedFilteredList.detections).map(detection => ({
  //       ...detection,
  //       ...detection?.versioning,
  //       sectionId
  //     }));
  //     setFilteredFrameData(detectionsList);
  //   } else {
  //     setFilteredFrameData([]);
  //   }
  // }, [stitchCurrentTime, appliedFilters, distressProjectPredictions]);
  useEffect(() => {
    setImageDimensions({
      height: stitchVideoHeight,
      width: stitchlVideoWidth,
    })
  }, [vidDivDimen])
  useEffect(() => {
    const divHeight = vidDivRef?.current?.clientHeight
    const divWidth = vidDivRef?.current?.clientWidth - 0;
    const imageHeight = stitchVideoHeight;
    const imageWidth = stitchlVideoWidth;
    const asDiv = (divHeight / divWidth)
    const asImg = (imageHeight / imageWidth)
    const ratio = asDiv / asImg
    let svgWidth = 0
    let svgHeight = 0
    if (ratio >= 1) {
      svgWidth = divWidth
      svgHeight = asImg * divWidth
    } else {
      svgHeight = divHeight
      svgWidth = (1 / asImg) * divHeight
    }
    setSvgDimensions({
      width: svgWidth,
      height: svgHeight,
      scale: imageHeight / svgHeight,
      offsetTop: (divHeight - svgHeight) / 2,
      offsetLeft: (divWidth - svgWidth) / 2,
    })
  }, [imageDimensions])

  useEffect(() => {
    if (!isNaN(stitchCurrentTime)) {
      const videoTime = Math.floor(stitchCurrentTime * stitchFps);
      if (!isNaN(inputFrame)) {
        if (videoTime !== inputFrame) {
          setInputFrame(videoTime);
        }
      }
    }
  }, [stitchCurrentTime])

  useEffect(() => {
    if (distressArchiveIsDoneAndSent === true) {
      setOpenArchiveAlert(true);
      setDeleteBboxList([]);
      handleCloseDeleteDialog();
    }
  }, [distressArchiveIsDoneAndSent])
  useEffect(() => {
    if (updateDistressArchiveIsDoneAndSent === true) {
      setOpenEditAlert(true);
    }
  }, [updateDistressArchiveIsDoneAndSent])
  useEffect(() => {
    if (addDistressArchiveIsDoneAndSent === true) {
      setOpenAlert(true);
    }
  }, [addDistressArchiveIsDoneAndSent])
  ///////////////////////////////////////////////////////////////////////


  const tabs = Object.values(currentVideos).map((path, index) => (
    <Tab
      key={index}
      value={index + newCurrentPage * videosPerPage}
      label={`Video ${(index + newCurrentPage * videosPerPage) + 1}`}
      className={`${classes.tab} ${tabValue === (index + newCurrentPage * videosPerPage) ? classes.activeTab : ''}`}
      // className={`${classes.tab} ${segmentIndex === (index + newCurrentPage * videosPerPage) ? classes.activeTab : ''}`}
      sx={{ width: "25%", textTransform: 'none', fontWeight: 'bold', fontSize: '16px' }}
    />
  ));

  const { status, error, data } = useGenerateSignedUrl(
    selectVideo ? selectVideo.path : "", false
  );
  const isFetched = status === 'fetched';
  // const isFetched = false;
  filteredGpsData.sort((a, b) => {
    return a.groupEndIndex - b.groupEndIndex
  });
  Object.values(filteredGpsData).forEach((section) => {
    detectionsList = [...detectionsList, Object.values(section.detections)];
    Object.values(section.detections).forEach((eachData) => {
      gpsDataCombined = [...gpsDataCombined, { sectionId: section.sectionId, lat: eachData.lat, lng: eachData.lng, time: Date.now(), defectName: eachData.defectName, geoTime: (eachData.groupFrameNumber / FPS).toFixed(5) }]
    })
  });
  const doesGpsDataCombinedExists = gpsDataCombined?.length > 0
  const startEndLocation = {
    startLat: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lat : 0,
    startLng: doesGpsDataCombinedExists ? gpsDataCombined.at(0).lng : 0,
    endLat: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lat : 0,
    endLng: doesGpsDataCombinedExists ? gpsDataCombined.at(-1).lng : 0,
  }
  const getVidWindowSize = () => {
    const newHeight = videoRef?.current?.clientHeight;
    const newWidth = videoRef?.current?.clientWidth;
    setVidDivDimen({
      height: newHeight,
      width: newWidth
    });
  };
  useEffect(() => {
    props.advancedAnalysisSetting(appliedFilters, proid);
  }, [appliedFilters])
  useEffect(() => {
    window.addEventListener("load", setVidDivDimen(videoRef?.current === null ? 0 : videoRef?.current?.clientHeight))
    window.addEventListener("resize", getVidWindowSize, true);
  }, []);
  useEffect(() => {
    props.getDownSamplingData(proid);
  }, []);
  useEffect(() => {
    props.analysisSetting(selectedDefects, conditionMethod, proid);
  }, [selectedDefects, conditionMethod]);
  useEffect(() => {
    if (selectedDefects.length > 0) {
      handleGpsDataFilterChange({ target: { value: selectedDefects } });
    }
    else {
      let predictionsArray = Object.values(distressProjectPredictions);
      predictionsArray = predictionsArray.filter((eachSection) => eachSection.groupId === selectVideo.groupId);
      if (Object.values(appliedFilters).length > 0) {
        const getFilteredAppliedData = applyFiltersToSections(predictionsArray, appliedFilters);
        setFilteredGpsData(getFilteredAppliedData);
        return;
      }
      setFilteredGpsData(predictionsArray);
    }
  }, [distressProjectPredictions, selectVideo, appliedFilters, selectedDefects]);


  const handleGpsDataFilterChange = (event) => {
    const selectedDefectIds = event.target.value;
    setSelectedDefects(selectedDefectIds);
    const finalFilteredData = Object.values(distressProjectPredictions).filter((eachSection) => eachSection.groupId === selectVideo.groupId).map((section) => {
      const filteredDetections = Object.fromEntries(
        Object.entries(section.detections).filter(
          ([key, value]) => selectedDefectIds.includes(value?.versioning?.defectName ?? value.defectName)
        )
      );
      return {
        ...section,
        detections: filteredDetections,
      };
    });
    if (Object.values(appliedFilters).length > 0) {
      const getFilteredAppliedData = applyFiltersToSections(finalFilteredData, appliedFilters);
      setFilteredGpsData(getFilteredAppliedData);
      return;
    }
    setFilteredGpsData(finalFilteredData);

    // setIsFilterOpen(true);
  }

  const getSelectedDefectNames = () => {
    return defectNames
      .filter((defect) => selectedDefects.includes(defect.defectId))
      .map((defect) => defect.name);
  };
  const handlePreviousPage = () => {
    if (newCurrentPage > 0) {
      setNewCurrentPage(newCurrentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (newCurrentPage < totalPages - 1) {
      setNewCurrentPage(newCurrentPage + 1);
    }
  };
  const handleTabChange = (event, newValue) => {
    if (videosList.length > newValue) {
      setTabValue(newValue);
      setSelectVideo(videosList[newValue]);
      setSegmentIndex(0)
      setCurrentTime(0);
      setSections(0);
      navigate(`/home/pr/${proid}/${0}`);
    }
  }
  const handleNextFrame = () => {
    playerRef?.current?.forward(1 / FPS)
  }
  const handlePrevFrame = () => {
    playerRef?.current?.replay(1 / FPS)
  }
  const onCurrentTimeChange = (currentTime) => {
    setCurrentTime(Number(currentTime))
  }
  const handleSectionChangeMap = (sectionIndex) => {
    navigate(`/home/pr/${proid}/${sectionIndex}`);
    const currentSection = Object.values(filteredGpsData)[sectionIndex];
    const crossSectionalParameters = currentSection.crossSectionalParameters ?? false;
    const sectionId = currentSection['sectionId'];

    if (crossSectionalParameters) {
      const points = [
        Number(crossSectionalParameters['x1']),
        Number(crossSectionalParameters['y1']),
        Number(crossSectionalParameters['x2']),
        Number(crossSectionalParameters['y2'])
      ];
      setCrossSectionalPoints(points);
      props.depthCrossSectionalProfile(
        {
          x1: Number(crossSectionalParameters['x1']),
          y1: Number(crossSectionalParameters['y1']),
          x2: Number(crossSectionalParameters['x2']),
          y2: Number(crossSectionalParameters['y2'])
        },
        proid,
        sectionId
      );
    } else {
      const defaultPoints = {
        x1: Number(defaultCrossSectionalPoints[0]),
        y1: Number(defaultCrossSectionalPoints[1]),
        x2: Number(defaultCrossSectionalPoints[2]),
        y2: Number(defaultCrossSectionalPoints[3])
      };
      setCrossSectionalPoints(defaultCrossSectionalPoints);
      props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
    }

    setSections(sectionIndex);
    setSegmentIndex(sectionIndex);
    handlePinned(Object.values(filteredGpsData)[sectionIndex]);
    onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
  };
  const handleSectionChange = (event) => {
    const sectionIndex = event.target.value;
    navigate(`/home/pr/${proid}/${sectionIndex}`);
    // //////console.log(sectionIndex, 'sectionIndex12')
    const currentSection = Object.values(filteredGpsData)[sectionIndex];
    const crossSectionalParameters = currentSection?.crossSectionalParameters ?? false;
    const sectionId = currentSection && currentSection['sectionId'];
    if (crossSectionalParameters) {
      const points = [Number(crossSectionalParameters['x1']), Number(crossSectionalParameters['y1']), Number(crossSectionalParameters['x2']), Number(crossSectionalParameters['y2'])]
      setCrossSectionalPoints(points);
      props.depthCrossSectionalProfile({
        x1: Number(crossSectionalParameters['x1']), y1: Number(crossSectionalParameters['y1']), x2: Number(crossSectionalParameters['x2']), y2: Number(crossSectionalParameters['y2'])
      }, proid, sectionId);
    }
    else {
      const defaultPoints = {
        x1: Number(defaultCrossSectionalPoints[0]), y1: Number(defaultCrossSectionalPoints[1]),
        x2: Number(defaultCrossSectionalPoints[2]), y2: Number(defaultCrossSectionalPoints[3])
      }
      setCrossSectionalPoints(defaultCrossSectionalPoints);
      props.depthCrossSectionalProfile(defaultPoints, proid, sectionId);
    }

    setSections(sectionIndex);
    setSegmentIndex(sectionIndex);
    handlePinned(Object.values(filteredGpsData)[sectionIndex]);
    onSegmentChange(Object.values(filteredGpsData)[sectionIndex]);
  };
  const handlePinned = (data) => {
    // const check = data === null ? false : pinned === null ? false : data?.sectionId === pinned?.sectionId
    playerRef.current?.pause()
    if (data) {
      handleSeekTo((data.groupInitIndex) / FPS)
    }
    // setPinned(check ? null : data)
  }
  const onSegmentChange = (segmentObj) => {
    playerRef?.current?.pause()
    const startTime = (segmentObj?.groupInitIndex) / FPS;
    playerRef?.current?.seek(startTime + 1 / FPS)
  }
  const handleSeekTo = (timeSeek) => {
    playerRef?.current?.seek(timeSeek)

  }
  const handleDataVisualizationChange = (event) => {
    setIsWholeDistressVisualization(event.target.checked);
  };
  const handleDepthCrossSectional = () => {
    setIsDepthCrossSectional(true);
  }
  const handleCloseDepthCrossSectional = () => {
    setIsDepthCrossSectional(false);
  }
  return (
    <>
      <CustomAlert open={openAlert} onClose={() => { setOpenAlert(false) }} text={addDistressArchiveMessage} severity={addDistressArchiveIsDoneAndSent ? "success" : "error"} />
      <CustomAlert open={openEditAlert} onClose={() => { setOpenEditAlert(false) }} text={updateDistressArchiveMessage} severity={updateDistressArchiveIsDoneAndSent ? "success" : "error"} />
      <CustomAlert open={openArchiveAlert} onClose={() => { setOpenArchiveAlert(false) }} text={distressArchiveMessage} severity={distressArchiveIsDoneAndSent ? "success" : "error"} />
      <SimpleDialogBox
        showError={distressArchiveIsDoneAndSent === true ? false : true}
        errorMessage={distressArchiveIsDoneAndSent === true ? "" : distressArchiveMessage}
        open={deleteDialog}
        onClose={handleCloseDeleteDialog}
        title='Archive distresses'
        bodyWidget={<>Are you sure you want to archive distresses?</>}
        showPositive
        width={1000}
        positiveButtonWidget={<>
          <Button size="medium" variant="contained"
            onClick={handleClearSelection}
            sx={{
              whiteSpace: "nowrap",
              marginRight: '8px',
              textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#025196',
                color: '#FFFFFF'
              }
            }}>Remove selections</Button>
          {showDistressArchiveLoader ?
            <>
              <Button variant="contained" disabled sx={{
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                "&.Mui-disabled": {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>
                <CircularProgress variant="indeterminate" size={15} sx={{ color: 'black' }} /><span style={{ paddingLeft: '15px' }}>Archiving...</span>
              </Button>
            </>
            :
            <Button size="medium" variant="contained"
              onClick={() => props.moveDistressMultipleToArchive(proid, deleteBboxList)}
              sx={{
                whiteSpace: "nowrap",
                textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#025196',
                  color: '#FFFFFF'
                }
              }}>Archive</Button>
          }
        </>
        }
      />
      <div style={{
        height: '100vh', width: '100vw',
        backgroundColor: '#E1E3E2',
      }}>

        {(editorMode ? (
          <div style={{ height: '100vh', width: '100vw' }}>

            <div style={{ height: '10vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                {isEditMode ? <></> : <CustomizedDividers
                  onOpenDeleteDialog={handleOpenDeleteDialog}
                  deleteBboxList={deleteBboxList}
                  deleteMultipleBbox={deleteMultipleBbox}
                  onSelectDelete={handleSelectDelete}
                  onDeselectDelete={handleDeselectDelete}
                  inputFrame={inputFrame}
                  setCurrentTime={setStitchCurrentTime}
                  totalFrames={totalFrames}
                  addNewBoundingBox={addNewBoundingBox}
                  playerRef={comparePlayerRef}
                  currentTime={stitchCurrentTime}
                  fps={stitchFps}
                  handlePrevFrame={handleEditorPrevFrame}
                  setPlaybackRate={setPlaybackRate}
                  playbackRate={playbackRate}
                  handleNextFrame={handleEditorNextFrame}
                  setEditorMode={setEditorMode}
                  closeBoundingBox={closeBoundingBox}
                  isAddingDistress={isAddingDistress}
                  onFrameChange={handleInputFrame}
                  dataInfo={distressData.dataInfoV2}
                />}
              </div>
              {isEditMode ? <div>
                <EditOptions
                  handleDelete={handleDelete}
                  fps={stitchFps}
                  allPolyPoints={gpsDataCombined}
                  currentTime={stitchCurrentTime}
                  projectPredictions={filteredGpsData}
                  svgDimensions={svgDimensions}
                  originalVideoWidth={stitchlVideoWidth}
                  proid={proid}
                  rectangles={rectangles}
                  addDistressV2={props.editorAddDistress}
                  updateDistressV2={props.editorUpdateDistress}
                  fieldValues={fieldValues}
                  isAddingDistress={isAddingDistress} setShowPointer={setShowPointer}
                  setFieldValues={setFieldValues} setSelectedId={setSelectedId}
                  setIsEditMode={setIsEditMode} handleChange={handleChange}
                  dataInfo={distressData.dataInfoV2}
                  drProjectData={drProjectData}
                  currentFrame={wholeProjectDetections[Math.floor(stitchFps * stitchCurrentTime)]}
                />

              </div> : <></>}
            </div>
            <div id='asset-player' style={{ padding: `${0}px`, height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
              <Card elevation={0} sx={{ height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px))`, backgroundColor: '' }}>
                <div ref={vidDivRef} style={{ height: `calc(${90}vh  - ${0}px - ${0 * 2}px - ${0 * 2}px)`, backgroundColor: 'transparent', width: '100%' }}>
                  <DistressEditorPlayer
                    startTime={0}
                    vidDivRef={vidDivRef}
                    drProjectData={drProjectData}
                    playerRef={comparePlayerRef}
                    onCurrentTimeChange={onStitchCurrentTimeChange}
                    playbackRate={playbackRate}
                    filteredFrameData={filteredFrameData}
                    fps={stitchFps}
                    svgDimensions={svgDimensions}
                    originalVideoWidth={stitchlVideoWidth}
                    distressData={distressData}
                    rectangles={rectangles}
                    setRectangles={setRectangles}
                    showPointer={showPointer}
                    isEditMode={isEditMode}
                    selectedId={selectedId}
                    deleteMultipleBbox={deleteMultipleBbox}
                    onSelectDelete={handleDeleteBbox}
                    setFieldValues={setFieldValues}
                    setSelectedRecId={setSelectedRecId}
                    setOpenDistressDialog={setOpenDistressDialog}
                    setSelectedId={setSelectedId}
                    setIsEditMode={setIsEditMode}
                    setShowPointer={setShowPointer}
                    openDistressDialog={openDistressDialog}
                    selectedRecId={selectedRecId}
                    handleDelete={handleDelete}
                  />
                </div>
              </Card>
            </div>
          </div>
        )
          : <Grid container spacing={0} sx={{ height: `calc(100vh - ${0}px)` }}>
            <Grid item xs={4} sm={4} md={5} lg={6} xl={6} sx={{ backgroundColor: '' }}>
              {/* heaader */}
              <div ref={headerRef} style={{ backgroundColor: '' }}>
                <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
                  <SurfaceEvaluationTopbar
                    handleFastDistress={handleFastDistress}
                    filteredGpsData={filteredGpsData} isFilterOpen={isFilterOpen}
                    selectedDefects={selectedDefects} handleGpsDataFilterChange={handleGpsDataFilterChange}
                    getSelectedDefectNames={getSelectedDefectNames} defectNames={defectNames}
                    startEndLocation={startEndLocation} proid={proid} drProjectData={drProjectData}
                    distressProjectPredictions={filteredGpsData} conditionMethod={conditionMethod}
                    setConditionMethod={setConditionMethod} sectionLength={sectionLength}
                    setSectionLength={setSectionLength}
                    fitersData={fitersData} setFiltersData={setFiltersData}
                    appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters}

                  />
                </div>
              </div>
              <div ref={tabRef} style={{ backgroundColor: '' }}>
                <div style={{ padding: su._createPadding(overallCustomBorderPadding, 'trl') }}>
                  <div
                    style={{
                      display: 'flex', alignItems: 'center',
                      backgroundColor: '#EFEFEF',
                      boxShadow: '0px 0px 10px rgba(64, 64, 64, 0.1)',
                      width: '100%',
                      borderBottomLeftRadius: '0',
                      borderBottomRightRadius: '0',
                      borderRadius: '15px', paddingInline: '10px', paddingBlock: '2px'
                    }}>
                    <IconButton onClick={handlePreviousPage} disabled={newCurrentPage === 0}>
                      <ArrowBackIosIcon />
                    </IconButton >

                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      sx={{ width: '100%' }}
                      indicatorColor=''
                      textColor=''
                    >
                      {tabs}
                    </Tabs>

                    <div style={{ marginLeft: 'auto' }}>
                      <IconButton onClick={handleNextPage} disabled={newCurrentPage === totalPages - 1} >
                        <ArrowForwardIosIcon />
                      </IconButton >
                    </div>
                  </div>
                </div>
              </div>
              {/* video player & map */}
              {headerHeight === 0 || tabRef === 0 ? <CustomProgress /> :
                <div style={{ height: `calc(100vh - ${headerHeight}px - ${tabHeight}px)`, backgroundColor: '' }}>
                  <div style={{ height: `${videoSectionHeight}%`, backgroundColor: '', padding: su._createPadding(overallCustomBorderPadding, 'trbl') }}>

                    <div ref={videoRef} style={{ height: '100%', backgroundColor: '' }}>
                      <Card sx={{ ...cardWhiteStyle }}>
                        {isFetched ? <SurfaceEvaluationVideoPlayer
                          // isVideoActive={isVideoActive}
                          // videoPlayerState={videoPlayerState}
                          // setVideoPlayerState={setVideoPlayerState}
                          onCurrentTimeChange={onCurrentTimeChange}
                          playerRef={playerRef} defaultRate={RATE}
                          startTime={filteredGpsData.length > 0 ? filteredGpsData[Number(secId ?? 0)] ? filteredGpsData[Number(secId ?? 0)]['groupInitIndex'] / FPS :
                            filteredGpsData[0]['groupInitIndex'] / FPS : 0}
                          fps={FPS} handleNextFrame={handleNextFrame} handlePrevFrame={handlePrevFrame} link={data}
                          height={videoRef.current === null ? 0 : videoRef.current?.clientHeight}
                          control={false} /> : <div style={{ height: `100%`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading video"} /></div>}
                      </Card>
                    </div>

                  </div>
                  <div style={{ height: `${mapSectionHeight}%`, backgroundColor: '', padding: su._createPadding(overallCustomBorderPadding, 'rbl') }}>
                    <div style={{ height: '100%', backgroundColor: '' }}>
                      <Card sx={{ ...cardWhiteStyle, height: '100%' }}>
                        {isFetched
                          // && isVideoActive 
                          ? <DistressMap isWholeDistressVisualization={isWholeDistressVisualization} distressPinned={distressPinned} setDistressPinned={setDistressPinned}
                            drProjectData={drProjectData} predictions={predictions}
                            segmentIndex={segmentIndex} FPS={FPS}
                            distressProjectPredictions={filteredGpsData}
                            selectVideo={selectVideo}
                            isGroupData={isGroupData}
                            zoom={18}
                            handleSectionChangeMap={handleSectionChangeMap}
                            pinned={pinned} handleSeekTo={handleSeekTo}
                            handlePinned={handlePinned}
                            conditionMethod={conditionMethod} /> : <div style={{ height: `100%`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading map"} /></div>}
                      </Card>
                    </div>
                  </div>
                </div>
              }
            </Grid>
            <Grid item xs={8} sm={8} md={7} lg={6} xl={6} sx={{ backgroundColor: '' }}>
              <div style={{ padding: SmartUtils.edgeInsets(overallCustomBorderPadding, 'tbr'), height: '100%' }}>
                <Card sx={{ borderRadius: SmartUtils.edgeInsets(15, 'tblr'), height: '100%' }}>
                  {isFetched
                    // && isVideoActive 
                    ? <SegmentShifterV2
                      isGroupData={isGroupData}
                      selectVideo={selectVideo}
                      handleSeekTo={handleSeekTo}
                      isWholeDistressVisualization={isWholeDistressVisualization}
                      handleDataVisualizationChange={handleDataVisualizationChange}
                      distressPinned={distressPinned}
                      setDistressPinned={setDistressPinned}
                      setPinned={setPinned}
                      isDepthCrossSectional={isDepthCrossSectional}
                      handleDepthCrossSectional={handleDepthCrossSectional}
                      handleCloseDepthCrossSectional={handleCloseDepthCrossSectional}
                      segmentIndex={segmentIndex}
                      setSegmentIndex={setSegmentIndex}
                      predictions={filteredGpsData}
                      pinned={pinned}
                      originalVideoHeight={height}
                      originalVideoWidth={width}
                      link={data}
                      FPS={FPS}
                      playerRef={playerRef}
                      proid={proid}
                      drProjectData={drProjectData}
                      handleSectionChange={handleSectionChange}
                      sections={sections}
                      setSections={setSections}
                      crossSectionalPoints={crossSectionalPoints}
                      setCrossSectionalPoints={setCrossSectionalPoints}
                      defaultCrossSectionalPoints={defaultCrossSectionalPoints}
                      currentTime={currentTime}
                      overallCustomBorderPadding={overallCustomBorderPadding}
                      conditionMethod={conditionMethod} /> : <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CustomProgress text={"Loading data"} /></div>}
                </Card>
              </div>
            </Grid>
          </Grid>)}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    project: state.project,
    datastore: state.datastore,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    depthCrossSectionalProfile: (points, proid, sectionId) => dispatch(depthCrossSectionalProfile(points, proid, sectionId)),
    saveDepthCrossSectionalPoints: (points, proid, sectionId) => dispatch(saveDepthCrossSectionalPoints(points, proid, sectionId)),
    getDownSamplingData: (proid) => dispatch(getDownSamplingData(proid)),
    analysisSetting: (selectedDefects, conditionMethod, proid) => dispatch(analysisSetting(selectedDefects, conditionMethod, proid)),
    advancedAnalysisSetting: (appliedFilters, proid) => dispatch(advancedAnalysisSetting(appliedFilters, proid)),
    editorAddDistress: (proid, sectionId, bbox, defectClass, transversePosition, defectProperties) => dispatch(editorAddDistress(proid, sectionId, bbox, defectClass, transversePosition, defectProperties)),
    editorUpdateDistress: (proid, sectionId, detectionId, bbox, defectClass, defectProperties) => dispatch(editorUpdateDistress(proid, sectionId, detectionId, bbox, defectClass, defectProperties)),
    moveDistressToArchive: (proid, sectionId, detectionId) => dispatch(moveDistressToArchive(proid, sectionId, detectionId)),
    moveDistressMultipleToArchive: (proid, detections) => dispatch(moveDistressMultipleToArchive(proid, detections)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AfterSurfaceEvaluationInferenceGroupDashboard);
