import React, { useEffect, useRef, useState } from 'react'
import CustomProgress from '../../../../../cidgets/CustomProgress';
import { connect } from 'react-redux';
import { Box, Button, Grid, Tooltip } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './table.css'
import SimpleDialogBox from '../../../../../cidgets/SimpleDialogBox';
import PinnedDistressFields from '../../../project/surfaceEvaluationComponents/PinnedDistressFields';
import ImageAnnotatorV3 from '../../../project/surfaceEvaluationComponents/ImageAnnotatorV3';
// import DefectImage from './DefectImage';
const DistressListTable = (props) => {
  const { allNetworkDefects, defectPinned, setDefectPinned, datastore, page, limit, drNetworkData } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [showMaskImage, setShowMaskImage] = useState(false);
  const [selectedDistressPinned, setSelectedDistressPinned] = useState(null);
  const { distressData } = datastore;

  const tableRef = useRef(null);
  const style = {
    "paddingLeft": "15px",
    "paddingRight": "15px",
    "width": "100%",
    "height": "100%",
    "overflow": "auto",
  }
  useEffect(() => {
    const element = document.getElementById(defectPinned?.defectId);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [defectPinned])
  if (!allNetworkDefects) {
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CustomProgress text={"Loading defects"} />
    </div>
  }
  const handlePinning = (data) => {
    const check = data === null ? false : defectPinned === null ? false : data.defectId === defectPinned.defectId
    setDefectPinned(check ? null : data)
  }
  const isRectangle = defectPinned ? defectPinned?.isRectangle : null;
  let bbox;
  const isAdvanceProjectType = drNetworkData.projectType === 4 ? true : false;
  const rectangleCondCheck = isRectangle === undefined || isRectangle === true;
  const newBbox = rectangleCondCheck ? [
    defectPinned?.versioning?.bbox?.[0] ?? defectPinned?.bbox?.[0],
    defectPinned?.versioning?.bbox?.[1] ?? defectPinned?.bbox?.[1],
    defectPinned?.versioning?.bbox?.[2] ?? defectPinned?.bbox?.[2],
    defectPinned?.versioning?.bbox?.[3] ?? defectPinned?.bbox?.[3]
  ] : defectPinned ? defectPinned?.versioning?.bbox ?? defectPinned?.bbox : [];
  const fillMap = {
    0: "#caee00",
    1: "#60EE00",
    3: "#00ee83",
    4: "#00eeda",
    10: "#ee1000"
  };
  const fill = fillMap[defectPinned?.defectName] || "#025196";
  function getCorner(pivotX, pivotY, diffX, diffY) {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    const angle = Math.atan2(diffY, diffX);

    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return [x, y]
  }
  function createPairs(boundingBox) {
    const pairs = [];
    for (let i = 0; i < boundingBox.length; i += 2) {
      pairs.push([boundingBox[i], boundingBox[i + 1]]);
    }
    return pairs;
  }
  function calculateRectangleCorners(x, y, width, height) {
    const topLeft = getCorner(x, y, 0, 0);
    const topRight = getCorner(x, y, width, 0);
    const bottomRight = getCorner(x, y, width, height);
    const bottomLeft = getCorner(x, y, 0, height);
    return [topLeft, topRight, bottomRight, bottomLeft];
  }
  if (!isRectangle) {
    const pairs = createPairs(newBbox);
    bbox = [{ defectName: defectPinned?.defectName, fill, pairs }];
  } else {
    const [x, y, width, height] = newBbox;
    const corners = calculateRectangleCorners(x, y, width, height);
    bbox = [{ defectName: defectPinned?.defectName, fill, pairs: corners }];
  }

  if (distressData?.length === 0) {
    return <>Loading...</>
  }
  const { defectNames, dataInfoV2: dataInfo } = distressData;
  if (dataInfo === undefined || dataInfo === null) {
    return <></>
  }

  const { severity, transversePosition, type } = dataInfo;

  const handleOpenModal = (data) => {
    setOpenDialog(true);
    setSelectedDistressPinned(data);
  }
  const handleCloseModal = () => {
    setOpenDialog(false);
    setShowMaskImage(false);
    // setdefectPinned(null);
  }
  return (
    <>
      <SimpleDialogBox
        open={openDialog}
        onClose={handleCloseModal}
        title="Distress details"
        width={600}
        cancelText='Close'
        bodyWidget={
          <>
            <Box sx={{ marginBottom: '20px' }}><PinnedDistressFields distressPinned={selectedDistressPinned} dataInfo={dataInfo} drProjectData={drNetworkData} /></Box>
            {/* <DefectImage allNetworkDefects={allNetworkDefects} defectPinned={defectPinned} /> */}
            {/* {isAdvanceProjectType ?
              <Grid container spacing={0} sx={{ height: '100%' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ImageAnnotatorV3 imageUrl={showMaskImage ? defectPinned?.maskPath : defectPinned?.stitchPath} defaultHeight={85} defaultPadding={16} imageHeight={defectPinned?.HRHeight} imageWidth={defectPinned?.HRWidth} drawMap={showMaskImage ? false : true} editBox={false}
                    nonEditableBbox={bbox} />
                </Grid>
              </Grid>
              :
              <Grid container spacing={1} sx={{ height: '100%' }}>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ImageAnnotatorV3
                    distressPinned={defectPinned}
                    imageUrl={showMaskImage ? defectPinned?.maskPath : defectPinned?.stitchPath}
                    defaultHeight={85}
                    defaultPadding={16}
                    imageHeight={defectPinned?.HRHeight} imageWidth={defectPinned?.HRWidth}
                    drawMap={showMaskImage ? false : true} editBox={false}
                    nonEditableBbox={bbox} />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ImageAnnotatorV3 imageUrl={defectPinned?.depthMapPath} defaultHeight={85} defaultPadding={16} imageHeight={defectPinned?.HRHeight} imageWidth={defectPinned?.HRWidth} drawMap={false} editBox={false} />
                </Grid>
              </Grid>} */}
          </>
        }
      // showPositive
      // positiveButtonWidget={<>
      //   <Button size="medium" variant="contained"
      //     onClick={() => { setShowMaskImage(!showMaskImage) }}
      //     sx={{
      //       textTransform: 'initial', backgroundColor: '#025196', color: '#FFFFFF',
      //       '&:hover': {
      //         backgroundColor: '#025196',
      //         color: '#FFFFFF'
      //       }
      //     }}>Show {showMaskImage ? "stitched" : "mask"}</Button>
      // </>}
      />
      <div style={style}>
        {allNetworkDefects && allNetworkDefects.length > 0 ?
          <table className="assetTable">
            <thead style={{}}>
              <tr>
                <th className="" style={{ width: '10%' }}>Number</th>
                <th className="" style={{ width: '25%' }}>Category</th>
                <th className="" style={{ width: '10%' }}>Transverse position</th>
                <th className="" style={{ width: '10%' }}>Area (m<sup>2</sup>)
                </th>
                {allNetworkDefects.projectType !== 4 && (
                  <th className="" style={{ width: '10%' }}>Depth (mm)</th>
                )}
                <th className="" style={{ width: '5%' }}></th>
              </tr>
            </thead>
            <tbody ref={tableRef}>
              {allNetworkDefects.map((e, i) => {
                const check = (e.defectId === defectPinned?.defectId ?? '')
                return <tr
                  key={e.defectId}
                  id={e.defectId}
                  style={{ transition: 'all ease 0.0s', ...check ? { backgroundColor: '#B0FFA4', border: '2px solid #60EE00', cursor: 'pointer' } : { cursor: 'pointer' } }}
                  role="button"
                >
                  <td style={{ width: '10%' }} onClick={() => { handlePinning(e) }}>{page * limit + i + 1}</td>
                  <td style={{ width: '25%' }} onClick={() => { handlePinning(e) }} className="capitalize-text">{Number(e.defectName) === 1 ? Number(e.type) === -1 || e.type?.length === 0 ? '' : type[e.type]?.name : ''} {Number(e.defectName) === 3 || Number(e.defectName) === 10 ? Number(e.severity) === -1 || e.severity?.length === 0 ? '' : severity[e.severity]['name'] : ''} {e.defectName?.length === 0 ? <span style={{ color: 'gray' }}><i>{'unnamed'}</i></span> : defectNames[e.defectName]}</td>
                  <td style={{ width: '10%' }} onClick={() => { handlePinning(e) }} className="">{Number(e.transversePosition) === -1 || e.transversePosition?.length === 0 ? '' : transversePosition[e.transversePosition]?.name}</td>
                  <td style={{ width: '10%' }} onClick={() => { handlePinning(e) }} className="">
                    {Number(e.area) === -1 || e.area?.length === 0 ? 0 : Number(e.area).toFixed(2)}
                  </td>
                  {allNetworkDefects.projectType !== 4 && (
                    <td style={{ width: '10%' }} onClick={() => { handlePinning(e) }} className="">{Number(e.depth) === -1 || e.depth?.length === 0 ? 'N/A' : Number(e.depth * 1000).toFixed(2)}</td>
                  )}
                  <td style={{ width: '5%' }} className=""
                    onClick={() => { handleOpenModal(e) }}
                  >
                    <Tooltip title={`View distress details`} arrow><KeyboardArrowRightIcon /></Tooltip>
                  </td>
                </tr>
              })}
            </tbody>
          </table> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No distresses defect found</div>
        }

      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    datastore: state.datastore,
  };
};

export default connect(mapStateToProps, null)(DistressListTable);