import React, { useState, useEffect } from 'react'
import { Snackbar, Tooltip, Typography, Box, Grid, IconButton, Dialog, Button, Card, Divider, CircularProgress } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import SimpleDialogBox from "../../../../cidgets/SimpleDialogBox";
import { useAccessControlContext } from '../../../../../contexts/AccessControlProvider';
import DistressPredicitionEditorV2 from './DistressPredicitionEditorV2';
import PinnedDistressFields from './PinnedDistressFields';
import { useKeyPress } from '../../../../../chooks/useKeyPress';
import { moveDistressToArchive, resetEditDistressDialog, resetArchiveDistressDialog, removeDistressErrorAndMessage } from "../../../../../store/actions/projectActions";
import { connect } from "react-redux";

const PinnedDistress = (props) => {
  const { permissions } = useAccessControlContext();
  const { canEditDefectDistress, canCopyDefectLinkDistress, canArchiveDefectDistress } = permissions;
  const { isGroupData = false, drProjectData, project, dataInfo, distressData, setDistressPinned, currentSection, predictions,
    segmentIndex, handleSegmentChange, previousDistress, distressIndex, nextDistress, dataList, proid, handleDistressPinned,
    distressPinned, handleCloseDialog } = props;
  const { showDistressArchiveLoader, isArchiveModalClose } = project;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleArchiveClick = () => {
    props.resetArchiveDistressDialog();
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };



  const handleClickOpenDialog = () => {
    props.resetEditDistressDialog()
    setOpenDialog(true);
  };

  const handleCloseDialogDistress = () => {
    setOpenDialog(false);
  };
  const handleShareClick = (outputId) => {
    setOpen(true)
    navigator.clipboard.writeText(`${window.location.href}/${outputId}`)
  }
  const NEXT = useKeyPress('ArrowRight')
  const PREV = useKeyPress('ArrowLeft')

  useEffect(() => {
    if (isArchiveModalClose === true) {
      props.removeDistressErrorAndMessage();
      setDistressPinned(null);
      handleDialogClose();
    }
  }, [isArchiveModalClose]);

  useEffect(() => {
    if (NEXT) {
      if (!(distressIndex === dataList.length - 1)) {
        nextDistress()
      }
    } else if (PREV) {
      if (!(distressIndex === 0)) {
        previousDistress()
      }
    }
  }, [NEXT, PREV])

  const cardLightStyle = {
    "border": "0px solid grey",
    "borderRadius": "8px",
    "padding": "5px",
    "backgroundColor": '#f5f1f1'
  }
  const outputId = `${currentSection.id}-${distressPinned.defectId}`;
  const handleArchiveDistress = () => {
    props.moveDistressToArchive(proid, currentSection.id, distressPinned.defectId);
    // setDistressPinned(null);
  }

  return (
    <>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied the link to clipboard"
      />
      <div id='table-pinned' style={{ height: `100%`, backgroundColor: 'transparent' }}>

        <Box sx={{ paddingInline: '15px', height: { xs: '40%', md: '20%' }, backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={3} sm={4} md={5} sx={{
              // backgroundColor:'purple',
              display: 'flex', justifyContent: 'start', alignItems: 'center'
            }} >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                <IconButton onClick={previousDistress} disabled={distressIndex === 0 ? true : false}>
                  <Tooltip title="Previous distress"><KeyboardArrowLeftIcon sx={{ whiteSpace: 'nowrap', fontSize: { xs: '16px', sm: '16px', md: '24px' }, color: distressIndex === 0 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                </IconButton>
                <Typography variant="h6" component="span" sx={{ whiteSpace: 'nowrap', display: { xs: 'none', sm: 'none', md: 'flex' }, fontWeight: 'bold', fontSize: { xs: '10px', sm: '10px', md: '16px' }, padding: `0px ${15 + 10}px 0px ${15 + 10}px` }}>Distress {distressIndex + 1} / {dataList.length}</Typography>

                <IconButton onClick={nextDistress} disabled={distressIndex === dataList.length - 1 ? true : false}>
                  <Tooltip title="Next distress"><KeyboardArrowRightIcon sx={{ whiteSpace: 'nowrap', fontSize: { xs: '16px', sm: '16px', md: '24px' }, color: distressIndex === dataList.length - 1 ? 'grey' : '#1E1E1E' }} /></Tooltip>
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={9} sm={8} md={7} sx={{
              // backgroundColor:'yellow',
              display: 'flex', justifyContent: 'end', alignItems: 'center'
            }}>

              {distressPinned.isRectangle === false ?
                <></>
                :
                <>
                  {canEditDefectDistress ? <Tooltip title='Edit this distress' sx={{ display: { xs: "flex", sm: "flex", md: 'none' }, }}>
                    <EditIcon
                      sx={{
                        fontSize: { xs: '16px', sm: '16px', md: '24px' },
                        display: { xs: "flex", sm: "flex", md: 'none' },
                        color: "#1E1E1E",
                        // marginInline: "25px",
                        cursor: "pointer",
                      }}
                      onClick={handleClickOpenDialog}
                    />
                  </Tooltip> : <></>}

                  {canEditDefectDistress ? < Typography
                    variant="body1"
                    onClick={handleClickOpenDialog}
                    sx={{
                      whiteSpace: 'nowrap',
                      display: { xs: "none", sm: "none", md: 'flex' },
                      //  marginRight: '10px',
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#4B0082",
                      color: "#000000",
                      marginRight: '20px'

                    }}
                  >
                    Edit this distress
                  </Typography> : <></>}
                </>
              }
              {canArchiveDefectDistress ? <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'nowrap',
                  display: { xs: "none", sm: "none", md: 'flex' },
                  //  marginRight: '10px',
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "#000000",
                  // marginRight: '20px'

                }}
                onClick={handleArchiveClick}
              >
                Move to bin
              </Typography> : <></>}

              <SimpleDialogBox
                open={isDialogOpen}
                onClose={handleDialogClose}
                title={'Confirm delete'}
                bodyWidget={
                  <p>Are you sure you want to move this distress to the bin?</p>
                }
                showPositive
                positiveButtonWidget={
                  showDistressArchiveLoader ?
                    <>
                      <Button variant="contained" disabled sx={{
                        "&.Mui-disabled": {
                          backgroundColor: '#025196',
                          color: '#ffffff'
                        },
                        marginInline: '2px',
                        textTransform: 'initial',
                        color: '#025196',
                        backgroundColor: '#025196', ':hover': {
                          backgroundColor: '#025196'
                        }
                      }} >
                        <CircularProgress variant="indeterminate" size={15} sx={{ color: 'white' }} /><span style={{ paddingLeft: '15px' }}>Moving...</span>
                      </Button>
                    </>
                    :
                    <>
                      <Button variant="contained"
                        sx={{
                          marginInline: '2px',
                          textTransform: 'initial',
                          backgroundColor: '#025196', ':hover': {
                            backgroundColor: '#025196'
                          },
                        }} onClick={handleArchiveDistress}>Accept</Button>
                    </>
                }
              />



              <Dialog fullScreen open={openDialog} onClose={handleCloseDialogDistress}>
                <DistressPredicitionEditorV2
                  isGroupData={isGroupData}
                  distressPinned={distressPinned}
                  dataInfo={dataInfo}
                  distressData={distressData}
                  currentSection={currentSection}
                  previousDistress={previousDistress}
                  distressIndex={distressIndex}
                  nextDistress={nextDistress}
                  handleCloseDialogDistress={handleCloseDialogDistress}
                  dataList={dataList}
                  handleSegmentChange={handleSegmentChange}
                  segmentIndex={segmentIndex}
                  predictions={predictions}
                  proid={proid}
                  drProjectData={drProjectData}
                  handleCloseDialog={handleCloseDialog}
                />
              </Dialog>
              {canCopyDefectLinkDistress ? <Tooltip title='Copy the link of this distress'>
                <LinkIcon
                  sx={{
                    fontSize: { xs: '16px', sm: '16px', md: '24px' },
                    color: "#1E1E1E",
                    marginInline: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => { handleShareClick(outputId) }}
                />
              </Tooltip> : <></>}
              <Tooltip title='Unpin this distress'>
                <ClearIcon
                  sx={{
                    fontSize: { xs: '16px', sm: '16px', md: '24px' },
                    color: "#1E1E1E",
                    // marginLeft: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => { handleDistressPinned(distressPinned) }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        <div style={{ marginLeft: '15px', marginRight: '15px', height: '5%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Box sx={{ width: "100%" }}>
                <Divider light />
              </Box>
            </Grid>
          </Grid>
        </div>

        <Box sx={{ paddingInline: '15px', height: { xs: '50%', md: '70%' }, width: '100%', backgroundColor: 'transparent', display: 'flex', justifyContent: 'start', alignItems: 'start' }}>

          <div style={{ backgroundColor: 'transparent', float: 'left', width: '100%', height: '100%' }}>
            <Card sx={{ ...cardLightStyle, height: '87%', width: '100%', overflow: 'auto' }} elevation={0}>
              <div style={{ padding: '5px', height: '100%', overflow: 'auto', backgroundColor: 'transparent' }}>
                <PinnedDistressFields distressPinned={distressPinned} dataInfo={dataInfo} drProjectData={drProjectData} />
              </div>
            </Card>
          </div>
        </Box>
      </div >
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    datastore: state.datastore,
    project: state.project,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // setCurrentProjectId: (proid) => dispatch(setCurrentProjectId(proid)),
    moveDistressToArchive: (proid, sectionId, detectionId) => dispatch(moveDistressToArchive(proid, sectionId, detectionId)),
    resetEditDistressDialog: () => dispatch(resetEditDistressDialog()),
    resetArchiveDistressDialog: () => dispatch(resetArchiveDistressDialog()),
    removeDistressErrorAndMessage: () => dispatch(removeDistressErrorAndMessage())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PinnedDistress);