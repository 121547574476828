import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Tooltip, Paper, Divider, Select, MenuItem, FormControl, InputLabel, Box, IconButton, Typography, TextField, Badge } from '@mui/material';
import { useState } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Info } from '@mui/icons-material';
import DistressBar from './distressColorBar';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SmartUtils from '../../../../../../helpers/SmartUtils';
const CustomizedDividers = ({ dataInfo, onOpenDeleteDialog, deleteBboxList, deleteMultipleBbox, onSelectDelete, onDeselectDelete, inputFrame, onFrameChange, closeBoundingBox, isAddingDistress, totalFrames, setCurrentTime, addNewBoundingBox, playerRef, setEditorMode, handlePrevFrame, handleNextFrame, currentTime, fps, setPlaybackRate, playbackRate }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const isVideoEnded = Math.floor(fps * currentTime) === totalFrames - 1;
  const handleReplay = () => {
    if (playerRef?.current) {
      setCurrentTime(0);
      playerRef?.current.seek(0);
      playerRef?.current.play();
      setIsPlaying(true);
    }
  }
  return (
    <>
      <Paper
        elevation={0}
        sx={(theme) => ({
          display: 'flex',
          border: `2px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
          backgroundColor: '#f5f5f5',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px 8px',
        })}
      >
        <Tooltip title="Dashboard" arrow placement='bottom'>
          <IconButton disabled={deleteMultipleBbox} aria-label="Dashboard" onClick={() => {
            setEditorMode(false);
          }}>
            <DashboardIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>

        <Divider flexItem orientation="vertical" sx={{ height: 'auto', mx: 0.5, my: 1, }} />
        <Box display="flex" alignItems="center">
          <Tooltip title="Previous frame" arrow placement='bottom'>
            <IconButton
              disabled={Math.floor(fps * currentTime) <= 0
                // || deleteMultipleBbox
              }
              aria-label="Previous frame" onClick={handlePrevFrame}>
              <KeyboardArrowLeftIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>

          <Typography variant="body2" sx={{ color: 'black' }}>
            Frame {Math.floor(fps * currentTime)}
          </Typography>

          <Tooltip title="Next frame" arrow placement='bottom'>
            <IconButton disabled={isVideoEnded
              // || deleteMultipleBbox
            } aria-label="Next frame" onClick={handleNextFrame}>
              <KeyboardArrowRightIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={`Navigate the video by frame (0-${totalFrames - 1})`} arrow placement='bottom'>
            <TextField
              // disabled={deleteMultipleBbox}
              label="Go to Frame"
              type="number"
              value={inputFrame}
              onChange={onFrameChange}
              variant="outlined"
              size='small'
              inputProps={{ min: 0, max: totalFrames }}
            />
          </Tooltip>
          <Tooltip title={isVideoEnded ? "replay" : isPlaying ? "pause" : "play"} arrow placement='bottom'>
            <IconButton
              // disabled={deleteMultipleBbox}
              aria-label={isVideoEnded ? "replay" : isPlaying ? "pause" : "play"} onClick={() => {
                if (isVideoEnded) {
                  handleReplay();
                }
                else if (isPlaying) {
                  setIsPlaying(false);
                  playerRef?.current?.pause();
                } else {
                  setIsPlaying(true);
                  playerRef?.current?.play();
                }
              }}>
              {isVideoEnded ? <ReplayIcon sx={{ color: 'black' }} /> : isPlaying ? <PauseIcon sx={{ color: 'black' }} /> : <PlayArrowIcon sx={{ color: 'black' }} />}
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 1 }}>
          <FormControl size="small">
            <InputLabel htmlFor="rate-select">Rate</InputLabel>
            <Select
              // disabled={deleteMultipleBbox}
              label="Rate"
              value={playbackRate}
              onChange={(e) => setPlaybackRate(e.target.value)
              }
              inputProps={{ name: 'rate', id: 'rate-select' }}
            >
              <MenuItem value={0.1}>0.1x</MenuItem>
              <MenuItem value={0.5}>0.5x</MenuItem>
              <MenuItem value={1}>1x</MenuItem>
              <MenuItem value={2}>2x</MenuItem>
              <MenuItem value={4}>4x</MenuItem>
              <MenuItem value={5}>5x</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

        {isAddingDistress ? <Tooltip title={"Close"} arrow placement='bottom'>
          <IconButton value="close" aria-label="close" onClick={closeBoundingBox}>
            <HighlightOffOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip> :
          <Tooltip title={"Add bounding boxes"} arrow placement='bottom'>
            <IconButton disabled={deleteMultipleBbox} value="add" aria-label="add" onClick={addNewBoundingBox}>
              <AddIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>}
        {deleteBboxList.length > 0 ?
          <Tooltip title={"Archive multiple detections"} arrow placement='bottom'>
            <IconButton value="delete" aria-label="delete" onClick={onOpenDeleteDialog}>
              <Badge
                badgeContent={deleteBboxList.length}
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              // max={rectangles}
              >
                <DeleteOutlineIcon sx={{ color: 'black' }} />
              </Badge>
            </IconButton>
          </Tooltip>
          : deleteMultipleBbox ?
            <Tooltip title={"Select multiple bounding box for archiving"} arrow placement='bottom'>
              <IconButton value="delete" aria-label="delete" onClick={onDeselectDelete}>
                <ToggleOnIcon sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
            : <Tooltip title={"Archive multiple bounding box"} arrow placement='bottom'>
              <IconButton value="delete" aria-label="delete" onClick={onSelectDelete}>
                <ToggleOffIcon sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>}
        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <Tooltip title={<DistressBar data={SmartUtils.getListOfObjectValuesData(dataInfo.defectName)} />} arrow placement='bottom'>
          <IconButton value="add" aria-label="add">
            <Info sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      </Paper>
    </ >
  );
}
export default CustomizedDividers;