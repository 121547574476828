import { useEffect, useState } from "react";
import { Player, BigPlayButton, ControlBar, PlaybackRateMenuButton, Shortcut, VolumeMenuButton } from 'video-react';
import POSTER from '../../../../../../assets/bg/POSTER1.png'
import { Dialog } from "@mui/material";
import AddNewPotholeFeature from "./AddNewPotholeFeature";


const PotholeVideoPlayer = ({ projectPredictions, onCurrentTimeChange,
    clearAddDefectErrorAndMsg, addDefectDoneAndSent,
    showAddDefectLoader, addDefectMessage, addBasicImagingDefect,
    basicImagingDataInfo = [], proid, allPolyPoints, openAddNewAssetDialog,
    handleCloseAddNewAssetDialog, originalVideoHeight,
    originalVideoWidth, shouldOffset = true, startTime = 0, link, playerRef,
    height, control = true, handleNextFrame, handlePrevFrame, fps = 24, disableControlsFully = false }) => {
    const [videoPlayerState, setVideoPlayerState] = useState(null);
    const editorVideoTime = localStorage.getItem('editorVideoTime');
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.video.playbackRate = 1;
        }
    }, []);
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    const handleBind = (state) => {
        setVideoPlayerState(state);
    }

    useEffect(() => {
        if (!videoPlayerState?.isActive) {
            playerRef.current?.play()
            playerRef.current?.pause()
        }
    }, videoPlayerState)

    useEffect(() => {
        localStorage.setItem('mainDashboardVideoTime', videoPlayerState?.currentTime)
        onCurrentTimeChange(videoPlayerState?.currentTime)
    }, [videoPlayerState])

    return (
        <>
            <Dialog
                fullScreen
                open={openAddNewAssetDialog}
                onClose={handleCloseAddNewAssetDialog}
            >
                <AddNewPotholeFeature projectPredictions={projectPredictions} clearAddDefectErrorAndMsg={clearAddDefectErrorAndMsg} addDefectMessage={addDefectMessage} showAddDefectLoader={showAddDefectLoader} addDefectDoneAndSent={addDefectDoneAndSent} addBasicImagingDefect={addBasicImagingDefect} basicImagingDataInfo={basicImagingDataInfo} proid={proid} allPolyPoints={allPolyPoints} handleClose={handleCloseAddNewAssetDialog} startTime={videoPlayerState?.currentTime} fps={fps} playerRef={playerRef} link={link} originalVideoHeight={originalVideoHeight}
                    originalVideoWidth={originalVideoWidth} />
            </Dialog>
            <div
                id="video-div"
                onContextMenu={e => {
                    e.preventDefault()
                }}
            >
                {/* shouldOffset ? (startTime + (1 / fps)) :  */}
                <Player startTime={editorVideoTime ?? startTime}
                    ref={playerRef} preload="auto" autoPlay={false} muted={true} src={link} poster={POSTER} fluid={false} height={height} width="auto" playsInline={false}>
                    <Shortcut clickable={true} />
                    <BigPlayButton position="center" />
                    <ControlBar className="video-styler" autoHide={false} disableDefaultControls={control} disableCompletely={disableControlsFully}>
                        <span style={{ cursor: 'pointer', color: "white", padding: '10px', textDecoration: 'none' }} title="Previous frame" role="button" onClick={handlePrevFrame}>{!control ? '<' : ''}</span>
                        <span style={{ color: "white", padding: '10px', }}>{videoPlayerState === null ? '' : 'Frame: ' + Math.floor(fps * videoPlayerState.currentTime)}</span>
                        <span style={{ cursor: 'pointer', color: "white", padding: '10px', textDecoration: 'none' }} title="Next frame" role="button" onClick={handleNextFrame}>{!control ? '>' : ''}</span>
                        {control ? <></> : <PlaybackRateMenuButton rates={[5, 4, 2, 1, 0.5, 0.1]} />}
                        <VolumeMenuButton disabled />
                    </ControlBar>
                </Player>

            </div>
        </>
    );
}

export default PotholeVideoPlayer;