import React, { useRef, useState, useEffect } from 'react';
import { Player, ControlBar, Shortcut, BigPlayButton } from 'video-react';
import { Stage, Layer, Rect, Transformer, Circle, Text, Group } from 'react-konva';
import "video-react/dist/video-react.css";
import POSTER from '../../../../../../assets/bg/POSTER1.png'
import { Dialog, DialogContent, Button } from '@mui/material';
import SmartUtils from "../../../../../../helpers/SmartUtils";

const TextWithBackground = ({ rect }) => {
    const textRef = useRef(null);
    const [textWidth, setTextWidth] = useState(0);
    const [textHeight, setTextHeight] = useState(0);

    useEffect(() => {
        if (textRef.current) {
            // getTextWidth()
            setTextWidth(textRef.current.width());
            setTextHeight(textRef.current.height());
        }
    }, [rect.defectName]);
    return (
        <Group key={rect.id}>
            {/* Background Rect */}
            <Rect
                x={rect.x - 5}
                y={rect.y - textHeight - 7}
                width={textWidth + 10}
                height={textHeight + 5}
                fill={"black"}
                cornerRadius={4}
            />
            {/* Text */}
            <Text
                ref={textRef}
                x={rect.x}
                y={rect.y - textHeight - 3}
                text={rect.defectName}
                fill="white"
                fontFamily="sans-serif"
                fontSize={10}
            />
        </Group>
    );
};
const EditorVideoPlayer = ({
    // videoPlayerState, setVideoPlayerState,
    onSelectDelete,
    deleteMultipleBbox,
    handleDelete, selectedRecId, setSelectedRecId, openDistressDialog, setOpenDistressDialog, basicImagingData,
    showPointer, setShowPointer, setFieldValues, selectedId, setSelectedId, isEditMode, setIsEditMode, svgDimensions, rectangles,
    setRectangles, onCurrentTimeChange, fps, playerRef, originalVideoWidth, startTime, vidDivRef, link, filteredFrameData, playbackRate }) => {
    const { defectNames } = basicImagingData;
    const stageRef = useRef(null);
    const layerRef = useRef(null);
    const transformerRef = useRef(null);
    const [videoPlayerState, setVideoPlayerState] = useState(null);
    const [videoDimensions, setVideoDimensions] = useState({
        width: vidDivRef?.current?.clientWidth || 640,
        height: vidDivRef?.current?.clientHeight || 360,
    });
    const [bbox, setBbox] = useState({ downXY1: [], downXY2: [], size: 0, isEnabled: false });
    const [hbbox, setHbbox] = useState({ downX: 0, downY: 0 });
    const mainDashboardVideoTime = localStorage.getItem("mainDashboardVideoTime");
    const svgStrokeColor = 'rgba(96,238,0, 0.9)';
    useEffect(() => {
        if (videoDimensions.width !== vidDivRef?.current?.clientWidth || videoDimensions.height !== vidDivRef?.current?.clientHeight) {
            setVideoDimensions({
                width: vidDivRef?.current?.clientWidth,
                height: vidDivRef?.current?.clientHeight
            });
        }
    }, [vidDivRef])
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.video.playbackRate = playbackRate; // Set to your desired initial playback rate
        }
    }, [playbackRate]);
    useEffect(() => {
        if (playerRef) {
            playerRef.current?.subscribeToStateChange(handleBind)
        }
    });
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current = null;
            }
        };
    }, [playerRef]);
    const handleBind = (state) => {
        setVideoPlayerState(state);
    }
    useEffect(() => {
        if (!videoPlayerState?.isActive) {
            playerRef.current?.play()
            playerRef.current?.pause()
        }
    }, videoPlayerState)
    useEffect(() => {
        localStorage.setItem('editorVideoTime', videoPlayerState?.currentTime)
        onCurrentTimeChange(videoPlayerState?.currentTime)
    }, [videoPlayerState])
    useEffect(() => {
        const time = Math.floor(fps * videoPlayerState?.currentTime);
        const ratio = svgDimensions.width / originalVideoWidth;
        if (!isNaN(time)) {
            let data = filteredFrameData.filter((each) => each.archived === '0').map((data) => {
                const customAllBboxes = typeof data.allBboxes === 'string' ? JSON.parse(data.allBboxes) : data.allBboxes;
                return {
                    id: data.id,
                    x: customAllBboxes[time] ? customAllBboxes[time].bbox[0] * ratio : 1,
                    y: customAllBboxes[time] ? customAllBboxes[time].bbox[1] * ratio : 1,
                    width: customAllBboxes[time] ? customAllBboxes[time].bbox[2] * ratio : 1,
                    height: customAllBboxes[time] ? customAllBboxes[time].bbox[3] * ratio : 1,
                    stroke: SmartUtils.detectionColorDistress(data.defectName),
                    strokeWidth: customAllBboxes[time] ? 3 : 0,
                    isEditable: data.frameNumber === Math.floor(time * fps) ? true : false,
                    defectName: defectNames ?
                        data.defectName === 1 ?
                            data.typeName ?
                                `${data.typeName} ${defectNames[data.defectName]}` :
                                defectNames[data.defectName] :
                            defectNames[data.defectName]
                        : 'Unknown Distress',
                }
            });
            setRectangles(data);
        }
    }, [filteredFrameData, svgDimensions, videoPlayerState?.currentTime]);
    useEffect(() => {
        if (bbox.downXY1.length === 2 && bbox.downXY2.length === 2) {
            const ratio = svgDimensions.width / originalVideoWidth;
            const [tlx, tly] = bbox.downXY1;
            const [brx, bry] = bbox.downXY2;
            const height = getDistanceBetweenPoints(tlx, tly, tlx, bry);
            const width = getDistanceBetweenPoints(tlx, tly, brx, tly);
            const newBboxId = SmartUtils.generateRandomId();
            const newBox = {
                id: newBboxId,
                x: tlx * ratio,
                y: tly * ratio,
                width: width * ratio,
                height: height * ratio,
                stroke: "blue",
                strokeWidth: 3,
                isEditable: true,
            };
            setBbox({ downXY1: [], downXY2: [], size: 0, isEnabled: false });
            setHbbox({ downX: 0, downY: 0 });
            setRectangles([...rectangles, newBox]);
            setSelectedRecId(newBboxId);
            setSelectedId(newBboxId);
            setIsEditMode(true);
            setShowPointer(false);
            setFieldValues({
                basicImagingClassState: '',
                sectionId: '',
                frameNumberState: '',
                gpsLat: '',
                gpsLng: '',
                classification: '',
                defectId: newBboxId,
            });
        }
    }, [bbox]);
    useEffect(() => {
        if (transformerRef.current) {
            const stage = transformerRef.current.getStage();
            const selectedShape = stage.findOne(`#${selectedId}`);
            transformerRef.current.nodes(selectedShape ? [selectedShape] : []);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [selectedId, rectangles]);

    const handleSelect = (rect) => {
        const { id, isEditable } = rect;
        if (isEditable) {
            const data = filteredFrameData.filter((each) => each.id === id);
            if (data.length > 0) {
                setFieldValues({
                    basicImagingClassState: data[0]['versioning'] ? data[0]['versioning']['defectName'] : data[0]['defectName'],
                    sectionId: data[0]['sectionId'],
                    frameNumberState: data[0]['versioning'] ? data[0]['versioning']['frameNumber'] : data[0]['frameNumber'] ?? '',
                    gpsLat: data[0]['versioning'] ? data[0]['versioning']['gpsLat'] : data[0]['gpsLat'],
                    gpsLng: data[0]['versioning'] ? data[0]['versioning']['gpsLong'] : data[0]['gpsLong'],
                    classification: data[0]['versioning'] ? data[0]['versioning']['typeIndex'] : data[0]['typeIndex'] ?? '',
                    defectId: data[0]['id'],
                });
            }
            setSelectedRecId(id);
            setOpenDistressDialog(true);
        }
    };
    const handleDialogClose = () => {
        setOpenDistressDialog(false);
    };
    const handleEdit = () => {
        setSelectedId(selectedRecId)
        setIsEditMode(true);
        setOpenDistressDialog(false);
    };
    const handleDragMove = (e, rect) => {
        const { id, isEditable } = rect;
        if (isEditable) {
            if (isEditMode) {
                const rect = rectangles.find((rect) => rect.id === id);
                const { width, height } = rect;
                const x = Math.min(Math.max(0, e.target.x()), videoDimensions.width - width);
                const y = Math.min(Math.max(0, e.target.y()), videoDimensions.height - height);
                e.target.position({ x, y });
                const updatedRectangles = rectangles.map((rect) => {
                    return rect.id === id
                        ? {
                            ...rect,
                            x: x,
                            y: y,
                        }
                        : rect;
                });
                setRectangles(updatedRectangles);
            }
        }
    };
    const handleTransformEnd = (e, rect) => {
        const { id, isEditable } = rect;
        if (isEditable) {
            if (isEditMode) {
                const node = e.target;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
                // const rect = rectangles.find((rect) => rect.id === id);
                let newWidth = node.width() * scaleX;
                let newHeight = node.height() * scaleY;

                if (node.x() < 0) {
                    newWidth = newWidth + node.x();
                }
                if (node.y() < 0) {
                    newHeight = newHeight + node.y();
                }

                // Clamp the new width and height within video dimensions
                const newX = Math.max(0, node.x())
                const newY = Math.max(0, node.y())
                newWidth = Math.max(0, Math.min(newWidth, svgDimensions.width - newX))
                newHeight = Math.max(0, Math.min(newHeight, svgDimensions.height - newY))
                node.scaleX(1);
                node.scaleY(1);

                const updatedRectangles = rectangles.map((rect) => {
                    return rect.id === id
                        ? {
                            ...rect,
                            x: newX,
                            y: newY,
                            width: newWidth,
                            height: newHeight,
                        }
                        : rect;
                });
                setRectangles(updatedRectangles);
            }
        }

    };
    const getDistanceBetweenPoints = (x1, y1, x2, y2) => {
        var a = x1 - x2;
        var b = y1 - y2;
        return Math.floor(Math.sqrt(a * a + b * b));
    }
    const handleMouseDown = (e) => {
        if (showPointer && !isEditMode) {
            let downXY1 = bbox.downXY1
            let downXY2 = bbox.downXY2
            if (downXY1.length !== 2) {
                downXY1 = [hbbox.downX, hbbox.downY]
            } else if (downXY2.length !== 2) {
                if (downXY1[0] === hbbox.downX || downXY1[1] === hbbox.downY) return;
                downXY2 = [hbbox.downX, hbbox.downY]
            }
            else {
                downXY1 = []
                downXY2 = []
            }
            setBbox({
                downXY1,
                downXY2,
                size: 0,
            })
        }

    }
    const handleMouseMove = (e) => {
        if (showPointer && !isEditMode) {
            const event = e.evt;
            const layerX = event.clientX - layerRef.current.offsetLeft - svgDimensions.offsetLeft;
            const layerY = event.clientY - layerRef.current.offsetTop - svgDimensions.offsetTop;
            setHbbox({
                downX: ((layerX)) * svgDimensions.scale,
                downY: ((layerY)) * svgDimensions.scale,
            })
        }

    }
    return (
        <div ref={layerRef} className="custom-container" style={{ padding: '0px' }}>
            <div className="inner" style={{ zIndex: 997 }}>
                <Player startTime={mainDashboardVideoTime ?? startTime} ref={playerRef} src={link} autoPlay={false} poster={POSTER} muted={true} preload='auto' width="auto" fluid={false} height={vidDivRef?.current?.clientHeight} playsInline={false}>
                    <Shortcut clickable={true} />
                    <BigPlayButton position="center" />
                    <ControlBar autoHide={false} disableCompletely={true} disableDefaultControls={true} />
                </Player>
            </div>
            <div className="inner" style={{ zIndex: 998, width: vidDivRef?.current?.clientWidth }}>
                <div style={{
                    height: '100%', width: '100%', backgroundColor: '', textAlign: 'center', display: 'flex',
                    justifyContent: 'center', alignItems: 'center', marginTop: '-0px'
                }}>
                    <Stage
                        ref={stageRef}
                        width={svgDimensions.width}
                        height={svgDimensions.height}
                        style={{ backgroundColor: 'rgba(34,55,245, 0.0)', cursor: 'pointer' }}
                        onMouseDown={(e) => { handleMouseDown(e) }}
                        onMouseMove={(e) => { handleMouseMove(e) }}

                    >
                        <Layer>
                            {rectangles.map((rect, i) => (
                                <>
                                    <Rect
                                        hitStrokeWidth={0}
                                        strokeScaleEnabled={false}
                                        fillAfterStrokeEnabled={false}
                                        key={i}
                                        id={rect.id}
                                        {...rect}
                                        draggable={isEditMode && rect.isEditable && rect.id === selectedId}
                                        onClick={() => { deleteMultipleBbox ? onSelectDelete(rect) : handleSelect(rect) }}
                                        onDragMove={(e) => handleDragMove(e, rect)}
                                        onTransformEnd={(e) => handleTransformEnd(e, rect)}
                                        dash={rect.isEditable ? [0, 0] : [10, 5]}
                                    />
                                    {rect.defectName && <TextWithBackground rect={rect} />}
                                </>
                            ))}
                            {bbox.downXY2.length !== 2 && bbox.downXY1.length === 2 && (< Rect
                                stroke={"blue"}
                                strokeWidth={3}
                                x={bbox.downXY1[0] * (svgDimensions.width / originalVideoWidth)}
                                y={bbox.downXY1[1] * (svgDimensions.width / originalVideoWidth)}
                                width={getDistanceBetweenPoints(
                                    (bbox.downXY1[0] * (svgDimensions.width / originalVideoWidth)),
                                    (bbox.downXY1[1] * (svgDimensions.width / originalVideoWidth)),
                                    (hbbox.downX * (svgDimensions.width / originalVideoWidth)),
                                    (bbox.downXY1[1] * (svgDimensions.width / originalVideoWidth))
                                )}
                                height={getDistanceBetweenPoints(
                                    (bbox.downXY1[0] * (svgDimensions.width / originalVideoWidth)),
                                    (bbox.downXY1[1] * (svgDimensions.width / originalVideoWidth)),
                                    (bbox.downXY1[0] * (svgDimensions.width / originalVideoWidth)),
                                    (hbbox.downY * (svgDimensions.width / originalVideoWidth))
                                )}
                            />)}
                            <Transformer ref={transformerRef} rotateEnabled={false} />
                            {showPointer && <Circle x={hbbox.downX / svgDimensions.scale} y={hbbox.downY / svgDimensions.scale}
                                radius={bbox.downXY2.length === 2 ? 3 : 2}
                                fill={bbox.downXY2.length === 2 ? 'red' : bbox.downXY1.length === 2 ? svgStrokeColor : 'black'}
                            />}
                        </Layer>
                    </Stage>

                </div>
            </div>
            <Dialog open={openDistressDialog} onClose={null} >
                <DialogContent>
                    {selectedId && selectedRecId && selectedId === selectedRecId ? <></> : <Button onClick={handleEdit} color="primary">Edit</Button>}
                    <Button onClick={handleDelete} color="primary">Delete</Button>
                    <Button onClick={handleDialogClose} color="primary">Close</Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditorVideoPlayer;
