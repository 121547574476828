import React from 'react'
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Card from '@mui/material/Card';
import { Avatar, Tooltip } from "@mui/material";
import { connect } from 'react-redux';
import { useState } from 'react';
import SimpleDialogBox from '../../../../cidgets/SimpleDialogBox';
import NotificationViewer from '../../../../cidgets/NotificationViewer';
const ProjectNotification = (props) => {
    const { drProjectData } = props
    const [openNotifs, setOpenNotifs] = useState(false);
    const handleNotifsOpen = () => {
        setOpenNotifs(true);
    };

    const handleNotifsClose = () => {
        setOpenNotifs(false);
    };

    const calculateRead = () => {
        let counter = 0
        Object.values(props?.projNotifs ?? []).forEach((e) => {
            const readList = 'readList' in e ? e.readList : []
            if (!readList.includes(props.userData.uid)) {
                counter += 1
            }
        })

        return counter
    }
    const badgeCount = calculateRead();
    return (
        <>
            <SimpleDialogBox
                open={openNotifs}
                onClose={handleNotifsClose}
                title="Notifications"
                cancelText="Close"
                bodyWidget={
                    <NotificationViewer notifs={props.projNotifs} />
                }
                width='600'
            />

            {badgeCount > 0 ?
                <Tooltip title="Project notifications" arrow placement='bottom'>
                    <Badge
                        badgeContent={calculateRead()}
                        color="error"
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        max={9}
                    >
                        <IconButton
                            onClick={handleNotifsOpen}
                        >
                            <NotificationsActiveOutlinedIcon sx={{ color: 'black' }} />
                        </IconButton>

                    </Badge>
                </Tooltip> :
                <Tooltip title="Project notifications" arrow placement='bottom'>
                    <IconButton
                        onClick={handleNotifsOpen}
                    >
                        <NotificationsActiveOutlinedIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { proid } = ownProps
    return {
        projNotifs: state.firestore.ordered.ProjNotifs,
        userData: state.firebase.profile,
    };
};
export default connect(mapStateToProps)(ProjectNotification);