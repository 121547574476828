import React from 'react';
import SmartUtils from '../../../../../../helpers/SmartUtils';
// const data = [
//     { key: 0, name: "Pothole" },
//     { key: 1, name: "Crack" },
//     { key: 2, name: "Edge Deterioration" },
//     { key: 3, name: "Bleeding" },
//     { key: 4, name: "Crack Seals" },
//     { key: 5, name: "Patching" },
//     { key: 6, name: "Vegetation" },
// ];

const DistressBar = ({ data }) => (
    <div
        style={{
            position: 'absolute',
            top: '20px',
            zIndex: 1000,
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            color: '#025196',
        }}
    >
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <ul style={{ padding: '0px', margin: '0px' }}>
                {data.map((item) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: SmartUtils.detectionColorDistress(item.key.toString()),
                            }}></div>
                        <div style={{ fontSize: '12px', fontWeight: 500, whiteSpace: 'nowrap' }}> {item.name}</div>
                    </div>
                ))}
            </ul>

        </div>
    </div>
);

export default DistressBar;